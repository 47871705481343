import React, { useState, useEffect } from "react";
import './Selection.css';
//import Autocomplete from './Autocomplete';
import Hitfactor from "./Hitfactor";
import Hundoscenario from "./Hundoscenario";
import HitfactorPercentcalc from "./HitfactorPercentcalc"
import HFCalculation from "./HFCalculation"
import { Select, MenuItem, FormControl, TextField, Button, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import './Hitfactor.css';

const Selection = () => {
  const divisions = [

    { label: 'Open', value: 'Open' },

    { label: 'Limited', value: 'Limited' },

    { label: 'Limited 10', value: 'Limited10' },

    { label: 'Production', value: 'Production' },

    { label: 'Revolver', value: 'Revolver' },

    { label: 'Single Stack', value: 'Single Stack' },

    { label: 'Carry Optics', value: 'Carry Optics' },

    { label: 'PCC', value: 'PCC' },

    { label: 'Limited Optics', value: 'Limited Optics' },

  ];

  const [options, setOptions] = useState([]);
  const [staticSelectedOption, setStaticSelectedOption] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState("");
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [selectedClassifier, setSelectedClassifier] = useState(''); // Track the selected classifier
  const [showHundoscenario, setShowHundoscenario] = useState(true); // Track whether to show Hundoscenario component
  const [percentage, setPercentage] = useState("40"); // Track the percentage value
  const [minorMajor, setMinorMajor] = useState("Minor"); // Track the selected radio box value
  const [clickedCalculate, setClickedCalculate] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const minorDivisions = ["Production", "Carry Optics", "PCC", "Limited Optics"];


  const handleMinorMajorChange = (event) => {
    const { value } = event.target;

    // Check if the calculation has been performed and if the selected division is one of the minor divisions
    if (setClickedCalculate && minorDivisions.includes(selectedDivision)) {
      // If the calculation is performed and the selected division is one of the minor divisions, only allow "Minor" option
      setMinorMajor("Minor");
    } else {
      // If the calculation is not performed or the selected division is not a minor division, allow both "Minor" and "Major" options
      setMinorMajor(value);
    }
  };



  const fetchData = async () => {
    try {
      const response = await fetch('https://onlyhf.com/api/classifiers');
      const data = await response.json();
      if (Array.isArray(data)) {
        setOptions(transformData(data));
      } else {
        console.error('Error fetching options: Invalid response data format');
      }

    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };


  const transformData = (data) => {
    return data.map((item) => ({
      id: item[0],
      name: item[1],
      value: `${item[0]} ${item[1]}`,
    }));
  };

  useEffect(() => {
    setSelectedClassifier(selectedClassifier);
  }, [selectedClassifier]);

  useEffect(() => {
    setSelectedValue(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    setSelectedClassifier(selectedClassifier);
    setSelectedValue(selectedValue)
  }, [selectedClassifier, selectedValue]);


  const handleSelectedOption = (value, item) => {
    setSelectedValue(value === 'Select a Classifier' ? null : value);
    setSelectedClassifier(value.value);
    setShowHundoscenario(true);
    if (selectedDivision && value.value && shouldFetchData) {
      setShouldFetchData(false);
    }
  };


  const handleCalculateClick = () => {
    if (!isCalculating) {
      setIsCalculating(true);
      setErrorMessage('');
      setTimeout(() => {
        setIsCalculating(false);
      }, 1000);

      if (isCalculating) {
        setErrorMessage('Please wait for 1 second before calculating again.');
      } else {
        setShowHundoscenario(false);
        setClickedCalculate(true);
      }
    }
    else {
      setErrorMessage('Please wait for 1 second before calculating again.');
    }
  };



  useEffect(() => {
    fetchData();
  }, []);


  const handleStaticDropdownChange = (event) => {
    setStaticSelectedOption(event.target.value);
    setSelectedDivision(event.target.value);
    setShouldFetchData(true);
  };

  const handlePercentageInputClick = () => {
    setPercentage("");
  };

  const handleInputFocus = () => {
    if (selectedValue !== null) {
      setSelectedValue(null);
    }
  };

  const handleReset = (event) => {
    event.preventDefault();
    setPercentage('40');
    setErrorMessage('');
    setMinorMajor('Minor');
    setShowHundoscenario(true);
  };

  const handlePercentageInputBlur = () => {
    const value = parseFloat(percentage);
    if (isNaN(value)) {
      setPercentage("40.00");
    } else {
      const clampedValue = Math.min(100, Math.max(40, value));
      setPercentage(clampedValue.toFixed(2));
    }
  };


  const isOptionEqualToValue = (option, value) => {
    return option.value === value.value;
  };

  return (
    <form className="Selection">

      <h2>Division</h2>
      <div style={{ width: '200px', margin: '0 auto' }}>
        <FormControl fullWidth>
          <Select
            labelId="staticDropdownLabel"
            id="staticDropdown"
            value={staticSelectedOption}
            onChange={handleStaticDropdownChange}
            displayEmpty
          >
            <MenuItem value="" disabled selected>
              Select a division
            </MenuItem>
            {divisions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div>
        <h2>Classifier</h2>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.value}
          value={selectedValue}
          onChange={(event, newValue) => handleSelectedOption(newValue)}
          onFocus={handleInputFocus}
          onClick={handleInputFocus}
          isOptionEqualToValue={isOptionEqualToValue}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <input
                id="autocomplete-input"
                type="text"
                {...params.inputProps}
                placeholder="Select a Classifier"
                style={{ width: '200px', padding: '10px' }}
              />
            </div>
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props}>{option.value}</li>
          )}
        />
      </div>

      {(
        <HFCalculation
          selectedDivision={selectedDivision}
          selectedClassifier={selectedClassifier}
        />
      )}

      {(
        <Hitfactor
          selectedDivision={selectedDivision}
          selectedClassifier={selectedClassifier}
        />
      )}

      <div>
        <p></p>
      </div>

      <div>
        <h2>Desired Percentage</h2>
        <p>(Optional)</p>
        <TextField
          type="text"
          name="userText"
          placeholder="100.00"
          value={percentage}
          onChange={(e) => {
            setPercentage(e.target.value);
          }}
          onBlur={handlePercentageInputBlur}
          onClick={handlePercentageInputClick}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleCalculateClick();
            }
          }}
        />
        <div>
          <FormControl component="fieldset">
            <RadioGroup row aria-label="minorMajor" name="minorMajor" value={minorMajor} onChange={handleMinorMajorChange}>
              <FormControlLabel
                value="Minor"
                control={<Radio />}
                label="Minor"
              />
              <FormControlLabel
                value="Major"
                control={<Radio disabled={["Production", "Carry Optics", "PCC", "Limited Optics"].includes(selectedDivision)} />}
                label="Major"
              />
            </RadioGroup>
          </FormControl>
          <br />
          <Button variant="contained" onClick={handleCalculateClick}>Calculate</Button>
          <br /><br />
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <form onSubmit={(e) => e.preventDefault()}>
            <Button variant="outlined" onClick={handleReset}>Reset</Button>
          </form>
        </div>
      </div>

      <p></p>

      {showHundoscenario ? (
        <Hundoscenario
          selectedDivision={selectedDivision}
          selectedClassifier={selectedClassifier}
        />
      ) : (
        <HitfactorPercentcalc
          selectedDivision={selectedDivision}
          selectedClassifier={selectedClassifier}
          percentage={percentage}
          minorMajor={minorMajor}
          clickedCalculate={clickedCalculate}
          setClickedCalculate={setClickedCalculate}
          setMinorMajor={setMinorMajor}
        />
      )}
    </form>


  );
}

export default Selection;