import { useState, useEffect } from "react";
import { TextField, Button, Typography } from '@mui/material';
const HFCalculation = ({ selectedDivision, selectedClassifier }) => {
    const [data, setData] = useState([]);
    const [results, setResults] = useState([0, "Unknown"]);
    const [isCalculatingHF, setIsCalculatingHF] = useState(false);
    const [HF, setHitfactor] = useState("");
    const [showResult, setShowResult] = useState(false);
    const [errorMessage2, setErrorMessage2] = useState('');

    const fetchData = async (division, classifier) => {
        try {
            const response = await fetch(`https://onlyhf.com/api/classificationPercentage?division=${division}&classifier=${classifier}`);
            const datainput = await response.json();
            setData(datainput);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleHFInputBlur = () => {
        const value = parseFloat(HF);
        const clampedValue = Math.min(200, Math.max(0.1, value));
        setHitfactor(clampedValue);
    };

    const handleHFInputClick = () => {
        setHitfactor("");
    };

    const handleCalculateHFClick = (event) => {
        event.preventDefault();
        if (!isCalculatingHF) {
            setIsCalculatingHF(true);
            setShowResult(true);
            setErrorMessage2('');
            setTimeout(() => {
                setIsCalculatingHF(false);
            }, 1000);

            if (isCalculatingHF) {
                setErrorMessage2('Please wait for 1 second before calculating again.');
            } else {
                setIsCalculatingHF(true);
                if (selectedDivision && selectedClassifier && HF !== "" && HF !== "NaN") {
                    fetchData(selectedDivision, selectedClassifier);
                    const calculatedResults = performCalculations(data, HF);
                    setResults(calculatedResults);
                }
            }
        }
        else {
            setErrorMessage2('Please wait for 1 second before calculating again.');
        }
    };

    const handleResetHF = (event) => {
        event.preventDefault();
        setShowResult(false);
        setHitfactor("");
        setErrorMessage2('');
    };

    useEffect(() => {
        if (selectedDivision && selectedClassifier) {
            fetchData(selectedDivision, selectedClassifier);
        }
    }, [selectedDivision, selectedClassifier]);

    const performCalculations = (data, HF) => {
        if (data[0] && data[0].length > 0 && !isNaN(HF)) {
            const ClassArray = ['GM', 'M', 'A', 'B', 'C', 'D'];
            for (let i = 1; i < data[0].length; i++) {
                if (HF >= data[0][i]) {
                    return [((HF / data[0][0]) * 100).toFixed(2), ClassArray[i - 1]];
                }
            }
            return [((HF / data[0][0]) * 100).toFixed(2), ClassArray[5]];
        } else {
            return [0, "Unknown"]; // Return default values if data is not available
        }
    };


    return (
        <div>
            <h2>Enter Hit Factor</h2>
            <p>(Optional)</p>
                <TextField
                    type="text"
                    name="userText"
                    placeholder="6.9420"
                    value={HF}
                    onChange={(e) => {
                    setHitfactor(e.target.value);
                    }}
                    onBlur={handleHFInputBlur}
                    onClick={handleHFInputClick}
                    onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleCalculateHFClick();
                    }
                    }}
                />
                <br />
                <br />
                <Button variant="contained" onClick={handleCalculateHFClick}>Calculate</Button>
                <br />
                <form onSubmit={(e) => e.preventDefault()}>
                    <Button onClick={handleResetHF}>Reset</Button>
                </form>
                {errorMessage2 ? (
                    <Typography variant="body2" className="error-message">{errorMessage2}</Typography>
                ) : showResult ? (
                    <Typography variant="body2">Percentage: {results[0]}% - {results[1]}</Typography>
                ) : null}
        </div>
    )
};


export default HFCalculation