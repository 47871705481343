import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import { MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import FilterDialog from './FilterDialog';
import FilterNames from './FilterNames';
import FilterStages from './FilterStages';
import SearchMatches from './SearchMatches';
import './Search.css';

const Search = ({ onSelect }) => {
  const [uneditOverall, setUneditOverall] = useState(null);
  const [formattedMatch, setFormattedMatch] = useState(null);
  const [formattedMatchCopy, setFormattedMatchCopy] = useState(null);
  const [currentStage, setCurrentStage] = useState(null);
  const [transformedStageData, setTransformedStageData] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [nameList, setnameList] = useState({});
  const [selectedFilter, setSelectedFilter] = useState({
    division: [],
    class: [],
    categories: []
  });
  const [selectedNames, setSelectedNames] = useState([]);
  const [recalculateChecked, setRecalculateOptions] = useState(false);
  const [recalculateNamesChecked, setRecalculateNamesChecked] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDetailedScoring, setShowDetailedScoring] = useState(true);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [currentSelectedStages, setCurrentSelectedStages] = useState([]);
  const [totalSteel, setTotalSteel] = useState('');
  const [totalMissedSteel, setTotalMissedSteel] = useState('');
  const [totalAlpha, setTotalAlpha] = useState('');
  const [totalCharlie, setTotalCharlie] = useState('');
  const [totalDelta, setTotalDelta] = useState('');
  const [totalMiss, setTotalMiss] = useState('');
  const [totalNS, setTotalNS] = useState('');
  const [totalNPM, setTotalNPM] = useState('');
  const [totalFinalTime, setTotalFinalTime] = useState('');
  const [totalPF, setTotalPF] = useState('');
  const [totalOTS, setTotalOTS] = useState('');
  const [totalDivision, setTotalDivision] = useState('');
  const [totalProcedural, setTotalProcedural] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [filterout, setFilterout] = useState(false);
  const disabledDivisions = ['CO', 'LO', 'PROD', 'PCC', 'PO'];


  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleMatchSelect = async (id) => {
    onSelect(id);
    try {
      const response = await fetch(`https://onlyhf.com:5000/api/pullmatchdata?matchid=${id}`);
      if (!response.ok) {
        throw new Error('Failed to load JSON data');
      }

      const jsonDataString = await response.text();
      const jsonData = JSON.parse(jsonDataString);
      const match = jsonData[0];
      const formatted_Match = {
        matchName: match.match_name,
        clubName: match.club_name,
        matchType: match.match_type,
        clubCode: match.club_code,
        matchID: match.match_id,
        stages: formatStages(match.stages)
      };
      const formatted_Match_copy = {
        matchName: match.match_name,
        clubName: match.club_name,
        matchType: match.match_type,
        clubCode: match.club_code,
        matchID: match.match_id,
        stages: formatStages(match.stages)
      };
      const deepCopyFormattedMatch = JSON.parse(JSON.stringify(formatted_Match_copy));
      setUneditOverall(deepCopyFormattedMatch);
      setFormattedMatchCopy(deepCopyFormattedMatch);

      formatted_Match.stages.unshift(calculateOverallStage(formatted_Match.stages));
      setFormattedMatch(formatted_Match);
      setCurrentStage(formatted_Match.stages[0]);
    } catch (error) {
      console.error(error);
    }

  };

  useEffect(() => {
    const deepCopyFormattedMatch = JSON.parse(JSON.stringify(formattedMatchCopy));
    setUneditOverall(deepCopyFormattedMatch);
  }, [uneditOverall]);

  useEffect(() => {
    if ((recalculateChecked || recalculateNamesChecked) && currentStage.stageNumber === 'Overall') {
      formattedMatch.stages.shift();
      formattedMatch.stages.unshift(calculateOverallStage(formattedMatch.stages, selectedNames, selectedFilter));
      setCurrentStage(formattedMatch.stages[0]);
    }

  }, [recalculateChecked, recalculateNamesChecked, selectedNames, selectedFilter]);

  const calculateOverallStage = (stages, selected_names = null, selected_Filters = null) => {
    let overallData = {};
    let sumMaxPoints = 0;

    stages.forEach(stage => {
      if (stage.stageNumber !== 'Overall') {
        let shooterArray = stage.stageData;
        shooterArray.forEach(shooter => {
          if (shooter.name.includes("(DQ)") || shooter.name === '-' || shooter.DQed) {
            return;
          }
          if (selected_names !== null) {
            const selectedIDsArray = selected_names.map(({ id }) => id);
            if (selectedIDsArray.includes(shooter.shooterID)) {
              return;
            }
          }
          if (selected_Filters !== null) {
            if (selected_Filters.division.length > 0) {
              if (!selected_Filters.division.includes(shooter.division)) {
                return;
              }
            }

            if (selected_Filters.class.length > 0) {
              if (!selected_Filters.class.includes(shooter.classification)) {
                return;
              }
            }

            if (selected_Filters.categories.length > 0) {
              if (!selected_Filters.categories.some(category => shooter.categories.includes(category))) {
                return;
              }
            }
          }

          if (!overallData[shooter.shooterID]) {
            overallData[shooter.shooterID] = { ...shooter, points: 0, scoring: [], procedurals: [], finalTime: 0 };
            nameList[shooter.shooterID] = shooter.name;
          }
          shooter.scoring.forEach(score => {
            if (Array.isArray(score)) {
              score.forEach(element => {
                overallData[shooter.shooterID].scoring.push(element);
              });
            } else {
              overallData[shooter.shooterID].scoring.push(score);
            }
          });
          shooter.procedurals.forEach(procedural => {
            if (Array.isArray(procedural)) {
              procedural.forEach(element => {
                overallData[shooter.shooterID].procedurals.push(element);
              });
            } else {
              overallData[shooter.shooterID].procedurals.push(procedural);
            }
          });
          overallData[shooter.shooterID].finalTime += shooter.finalTime;
        });

        const validShooters = stage.stageData.filter(shooter => {
          if (shooter.name.includes("(DQ)") || shooter.name === '-' || shooter.DQed) {
            return false;
          }
          if (selected_names && selected_names.some(({ id }) => id === shooter.shooterID)) {
            return false;
          }
          if (selected_Filters) {
            if (selected_Filters.division.length > 0 && !selected_Filters.division.includes(shooter.division)) {
              return false;
            }
            if (selected_Filters.class.length > 0 && !selected_Filters.class.includes(shooter.classification)) {
              return false;
            }
            if (selected_Filters.categories.length > 0 && !selected_Filters.categories.some(category => shooter.categories.includes(category))) {
              return false;
            }
          }
          // Include the shooter in validShooters
          return true;
        });
        const highestHF = Math.max(...validShooters.map(shooter => shooter.HF));

        stage.stageData.forEach(shooter => {
          if (shooter.name.includes("(DQ)") || shooter.name === '-' || shooter.DQed) {
            return;
          }
          if (selected_names !== null) {
            const selectedIDsArray = selected_names.map(({ id }) => id);
            if (selectedIDsArray.includes(shooter.shooterID)) {
              return;
            }
          }
          if (selected_Filters !== null) {
            if (selected_Filters.division.length > 0) {
              if (!selected_Filters.division.includes(shooter.division)) {
                return;
              }
            }

            if (selected_Filters.class.length > 0) {
              if (!selected_Filters.class.includes(shooter.classification)) {
                return;
              }
            }

            if (selected_Filters.categories.length > 0) {
              if (!selected_Filters.categories.some(category => shooter.categories.includes(category))) {
                return;
              }
            }
          }
          let pointsEarned;
          if (stage.FixedTime) {
            pointsEarned = shooter.HF;
          } else {
            pointsEarned = (stage.maximumStagePoints * (shooter.HF / highestHF)).toFixed(4);
          }
          overallData[shooter.shooterID].pointsEarnedForStage = parseFloat(pointsEarned);
        });


        Object.values(overallData).forEach(shooter => {
          shooter.points += shooter.pointsEarnedForStage;
        });

        sumMaxPoints += stage.maximumStagePoints;
      }
    });

    return {
      stageNumber: 'Overall',
      maximumStagePoints: sumMaxPoints,
      stageData: Object.values(overallData).map(shooter => ({
        ...shooter
      }))
    };
  };


  const formatStages = (stages) => {
    return Object.values(stages).map(stage => ({
      stageNumber: stage.stage_number,
      maximumStagePoints: stage.maximum_stage_points,
      FixedTime: stage.FixedTime,
      TotalSteel: stage.totalsteels,
      stageData: formatStageData(stage.stage_data)
    }));
  };

  const formatStageData = (stageData) => {
    return stageData.map(shooter => ({
      shooterID: shooter.shooter_id,
      classification: shooter.Classification,
      name: shooter.name,
      finalTime: shooter.final_time,
      USPSA_ID: shooter.USPSA_ID,
      division: shooter.division,
      HF: shooter.HF,
      ots: shooter.ots,
      powerFactor: shooter.power_factor,
      scoreTime: shooter.score_time,
      rawPts: shooter.raw_pts,
      points: shooter.points,
      splits: formatSplits(shooter.Splits),
      scoring: shooter.Scoring || [],
      stageID: shooter.stage_id || '',
      DQed: shooter.DQ,
      procedurals: shooter.procedurals || [],
      categories: shooter.categories || []
    }));
  };
  const formatSplits = (splits) => {
    return splits.map(split => ({
      time: split.v.split(';')[0],
      description: split.v.split(';')[1]
    }));
  };

  const calculateTotalScoring = (shooter) => {
    const scoring = shooter.scoring || [];
    const procedurals = shooter.procedurals || [];
    const letterCounts = { A: 0, C: 0, D: 0, M: 0, NS: 0, NPM: 0, P: 0 };
    scoring.forEach(score => {
      const letters = score.split('');
      letters.forEach(letter => {
        if (letter === 'N' && letter === 'S') {
          letterCounts['NS']++;
        }
        else if (letter === 'Z') {
          letterCounts['M']++;
        }
        else if (letter === 'V') {
          letterCounts['A']++;
        }
        else if (letter === 'P') {
          letterCounts['NPM']++;
        } else if (['A', 'C', 'D', 'M'].includes(letter)) {
          letterCounts[letter]++;
        }
      });
    });
    procedurals.forEach(procedural => {
      letterCounts['P']++;
    });
    const filteredCounts = Object.entries(letterCounts)
      .filter(([_, count]) => count !== 0);
    const output = filteredCounts
      .map(([letter, count]) => `${letter}:${count}`)
      .join(' ');

    return output;
  };




  const applyNameFilters = (selected_Names, currentStage, recalculateNames_Checked, filterout) => {
    let recalculatedData = [...currentStage.stageData];
    recalculatedData = filterEmpty(recalculatedData);
    setFilterout(filterout);
    if (recalculateNames_Checked) {
      if (selected_Names.length > 0 && filterout) {
        const selectedIDsArray = selected_Names.map(({ id }) => id);
        recalculatedData = recalculatedData.filter(shooter =>
          !selectedIDsArray.includes(shooter.shooterID)
        );

      }
      else if (selected_Names.length > 0) {
        const selectedIDsArray = selected_Names.map(({ id }) => id);
        recalculatedData = recalculatedData.filter(shooter =>
          selectedIDsArray.includes(shooter.shooterID)
        );
      }
      formattedMatch.stages.shift();
      formattedMatch.stages.unshift(calculateOverallStage(formattedMatch.stages, selected_Names, null));
    }

    if (recalculateChecked) {
      if (selectedFilter.division.length > 0) {
        recalculatedData = recalculatedData.filter(shooter => selectedFilter.division.includes(shooter.division));
      }
      if (selectedFilter.class.length > 0) {
        recalculatedData = recalculatedData.filter(shooter => selectedFilter.class.includes(shooter.classification));
      }
      if (selectedFilter.categories.length > 0) {
        recalculatedData = recalculatedData.filter(shooter =>
          shooter.categories.some(category => selectedFilter.categories.includes(category))
        );
      }
      formattedMatch.stages.shift();
      formattedMatch.stages.unshift(calculateOverallStage(formattedMatch.stages, null, selectedFilter));
    }


    if (currentStage.stageNumber !== 'Overall') {
      let sortedData;
      if (currentStage.FixedTime) {
        sortedData = recalculatedData.sort((a, b) => b.HF - a.HF);
        const firstPlaceHF = sortedData[0].HF;
        recalculatedData = sortedData.map((shooter, index) => {
          const finalTimeType = typeof shooter.finalTime;
          let finalTime = 'N/A';

          if (finalTimeType === 'string') {
            const finalTimeAsNumber = parseFloat(shooter.finalTime);
            if (!isNaN(finalTimeAsNumber)) {
              finalTime = finalTimeAsNumber.toFixed(2);
            }
          } else if (finalTimeType === 'number') {
            finalTime = shooter.finalTime.toFixed(2);
          }
          return {
            ...shooter,
            id: index + 1,
            name: shooter.name + '',
            points: shooter.HF,
            percentage: Math.max(0, Math.round((shooter.HF / firstPlaceHF) * 10000) / 100),
            ptspercentage: Math.max(0, ((shooter.rawPts / currentStage.maximumStagePoints) * 100).toFixed(2)),
            finalTime: finalTime,
            totalHits: calculateTotalScoring(shooter)
          };
        });
      }
      else {
        const filteredData = recalculatedData.filter(entry => entry.finalTime !== 0.00);
        sortedData = filteredData.sort((a, b) => b.HF - a.HF);
        const firstPlaceHF = sortedData[0].HF;
        recalculatedData = sortedData.map((shooter, index) => {
          const finalTimeType = typeof shooter.finalTime;
          let finalTime = 'N/A';

          if (finalTimeType === 'string') {
            const finalTimeAsNumber = parseFloat(shooter.finalTime);
            if (!isNaN(finalTimeAsNumber)) {
              finalTime = finalTimeAsNumber.toFixed(2);
            }
          } else if (finalTimeType === 'number') {
            finalTime = shooter.finalTime.toFixed(2);
          }
          if (finalTime === 0.00) {
            return;
          }
          return {
            ...shooter,
            id: index + 1,
            name: shooter.name + '',
            points: Math.max(0, Math.round((shooter.HF / firstPlaceHF) * currentStage.maximumStagePoints * 10000) / 10000).toFixed(4),
            percentage: Math.max(0, Math.round((shooter.HF / firstPlaceHF) * 10000) / 100),
            ptspercentage: Math.max(0, ((shooter.rawPts / currentStage.maximumStagePoints) * 100).toFixed(2)),
            finalTime: finalTime,
            totalHits: calculateTotalScoring(shooter)
          };
        });
      }

    } else {
      const sortedData = recalculatedData.sort((a, b) => b.points - a.points);
      const firstPlace = sortedData[0].points;
      recalculatedData = sortedData.map((shooter, index) => {
        const pointsType = typeof shooter.points;
        let points = 'N/A';

        if (pointsType === 'string') {
          const pointsAsNumber = parseFloat(shooter.points);
          if (!isNaN(pointsAsNumber)) {
            points = pointsAsNumber.toFixed(4);
          }
        } else if (pointsType === 'number') {
          points = shooter.points.toFixed(4);
        }

        const finalTimeType = typeof shooter.finalTime;
        let finalTime = 'N/A';

        if (finalTimeType === 'string') {
          const finalTimeAsNumber = parseFloat(shooter.finalTime);
          if (!isNaN(finalTimeAsNumber)) {
            finalTime = finalTimeAsNumber.toFixed(2);
          }
        } else if (finalTimeType === 'number') {
          finalTime = shooter.finalTime.toFixed(2);
        }
        if (finalTime === 0.00) {
          return;
        }
        return {
          ...shooter,
          id: index + 1,
          name: shooter.name + '',
          points: points,
          percentage: Math.max(0, Math.round((shooter.points / firstPlace) * 10000) / 100),
          ptspercentage: Math.max(0, ((shooter.rawPts / currentStage.maximumStagePoints) * 100).toFixed(2)),
          finalTime: finalTime,
          totalHits: calculateTotalScoring(shooter)
        };
      });
    }

    if (currentStage.stageNumber !== 'Overall') {
      let sortedData;
      if (currentStage.FixedTime) {
        sortedData = recalculatedData.sort((a, b) => b.HF - a.HF);
        const firstPlaceHF = sortedData[0].HF;
        recalculatedData = sortedData.map((shooter, index) => {
          const finalTimeType = typeof shooter.finalTime;
          let finalTime = 'N/A';

          if (finalTimeType === 'string') {
            const finalTimeAsNumber = parseFloat(shooter.finalTime);
            if (!isNaN(finalTimeAsNumber)) {
              finalTime = finalTimeAsNumber.toFixed(2);
            }
          } else if (finalTimeType === 'number') {
            finalTime = shooter.finalTime.toFixed(2);
          }
          return {
            ...shooter,
            id: index + 1,
            name: shooter.name + '',
            points: shooter.HF,
            percentage: Math.max(0, Math.round((shooter.HF / firstPlaceHF) * 10000) / 100),
            ptspercentage: Math.max(0, ((shooter.rawPts / currentStage.maximumStagePoints) * 100).toFixed(2)),
            finalTime: finalTime,
            totalHits: calculateTotalScoring(shooter)
          };
        });
      }
      else {
        const filteredData = recalculatedData.filter(entry => entry.finalTime !== 0.00);
        sortedData = filteredData.sort((a, b) => b.HF - a.HF);
        const firstPlaceHF = sortedData[0].HF;
        recalculatedData = sortedData.map((shooter, index) => {
          const finalTimeType = typeof shooter.finalTime;
          let finalTime = 'N/A';

          if (finalTimeType === 'string') {
            const finalTimeAsNumber = parseFloat(shooter.finalTime);
            if (!isNaN(finalTimeAsNumber)) {
              finalTime = finalTimeAsNumber.toFixed(2);
            }
          } else if (finalTimeType === 'number') {
            finalTime = shooter.finalTime.toFixed(2);
          }
          if (finalTime === 0.00) {
            return;
          }
          return {
            ...shooter,
            id: index + 1,
            name: shooter.name + '',
            points: Math.max(0, Math.round((shooter.HF / firstPlaceHF) * currentStage.maximumStagePoints * 10000) / 10000).toFixed(4),
            percentage: Math.max(0, Math.round((shooter.HF / firstPlaceHF) * 10000) / 100),
            ptspercentage: Math.max(0, ((shooter.rawPts / currentStage.maximumStagePoints) * 100).toFixed(2)),
            finalTime: finalTime,
            totalHits: calculateTotalScoring(shooter)
          };
        });
      }

    } else {
      const sortedData = recalculatedData.sort((a, b) => b.points - a.points);
      const firstPlace = sortedData[0].points;
      recalculatedData = sortedData.map((shooter, index) => {
        const pointsType = typeof shooter.points;
        let points = 'N/A';

        if (pointsType === 'string') {
          const pointsAsNumber = parseFloat(shooter.points);
          if (!isNaN(pointsAsNumber)) {
            points = pointsAsNumber.toFixed(4);
          }
        } else if (pointsType === 'number') {
          points = shooter.points.toFixed(4);
        }

        const finalTimeType = typeof shooter.finalTime;
        let finalTime = 'N/A';

        if (finalTimeType === 'string') {
          const finalTimeAsNumber = parseFloat(shooter.finalTime);
          if (!isNaN(finalTimeAsNumber)) {
            finalTime = finalTimeAsNumber.toFixed(2);
          }
        } else if (finalTimeType === 'number') {
          finalTime = shooter.finalTime.toFixed(2);
        }
        if (finalTime === 0.00) {
          return;
        }
        return {
          ...shooter,
          id: index + 1,
          name: shooter.name + '',
          points: points,
          percentage: Math.max(0, Math.round((shooter.points / firstPlace) * 10000) / 100),
          ptspercentage: Math.max(0, ((shooter.rawPts / currentStage.maximumStagePoints) * 100).toFixed(2)),
          finalTime: finalTime,
          totalHits: calculateTotalScoring(shooter)
        };
      });
    }


    if (!recalculateNames_Checked) {

      if (selected_Names.length > 0 && filterout) {

        const selectedIDsArray = selected_Names.map(({ id }) => id);
        recalculatedData = recalculatedData.filter(shooter =>
          !selectedIDsArray.includes(shooter.shooterID)
        );
      }
      else if (selected_Names.length > 0) {
        const selectedIDsArray = selected_Names.map(({ id }) => id);
        recalculatedData = recalculatedData.filter(shooter =>
          selectedIDsArray.includes(shooter.shooterID)
        );
      }
    }
    if (!recalculateChecked) {
      if (selectedFilter.division.length > 0) {
        recalculatedData = recalculatedData.filter(shooter => selectedFilter.division.includes(shooter.division));
      }
      if (selectedFilter.class.length > 0) {
        recalculatedData = recalculatedData.filter(shooter => selectedFilter.class.includes(shooter.classification));
      }
      if (selectedFilter.categories.length > 0) {
        recalculatedData = recalculatedData.filter(shooter =>
          shooter.categories.some(category => selectedFilter.categories.includes(category))
        );
      }
    }

    return recalculatedData;
  };

  const filterEmpty = (recalculated_Data) => {
    let forcedFilter = '-';
    recalculated_Data = recalculated_Data.filter(shooter => !forcedFilter.includes(shooter.name));
    forcedFilter = 'DQ';
    recalculated_Data = recalculated_Data.filter(shooter => !forcedFilter.includes(shooter.name));
    return recalculated_Data;
  }

  const applyselectedFilters = (selected_Filters, currentStage, recalculate_Checked) => {
    let recalculatedData = [...currentStage.stageData];

    recalculatedData = filterEmpty(recalculatedData);

    if (recalculateNamesChecked) {
      if (selectedNames.length > 0 && !filterout) {

        const selectedIDsArray = selectedNames.map(({ id }) => id);
        recalculatedData = recalculatedData.filter(shooter =>
          selectedIDsArray.includes(shooter.shooterID)
        );
      }
      else if (selectedNames.length > 0) {
        const selectedIDsArray = selectedNames.map(({ id }) => id);
        recalculatedData = recalculatedData.filter(shooter =>
          !selectedIDsArray.includes(shooter.shooterID)
        );
      }
    }

    if (recalculate_Checked) {
      if (selected_Filters.division.length > 0) {
        recalculatedData = recalculatedData.filter(shooter => selected_Filters.division.includes(shooter.division));
      }

      if (selected_Filters.class.length > 0) {
        recalculatedData = recalculatedData.filter(shooter => selected_Filters.class.includes(shooter.classification));
      }

      if (selected_Filters.categories.length > 0) {
        recalculatedData = recalculatedData.filter(shooter =>
          shooter.categories.some(category => selected_Filters.categories.includes(category))
        );
      }
    }
    if (!recalculatedData || recalculatedData.length === 0) {
      return [];
    }
    if (currentStage.stageNumber !== 'Overall') {
      let sortedData;
      if (currentStage.FixedTime) {
        sortedData = recalculatedData.sort((a, b) => b.HF - a.HF);
        const firstPlaceHF = sortedData[0].HF;
        recalculatedData = sortedData.map((shooter, index) => {
          const finalTimeType = typeof shooter.finalTime;
          let finalTime = 'N/A';

          if (finalTimeType === 'string') {
            const finalTimeAsNumber = parseFloat(shooter.finalTime);
            if (!isNaN(finalTimeAsNumber)) {
              finalTime = finalTimeAsNumber.toFixed(2);
            }
          } else if (finalTimeType === 'number') {
            finalTime = shooter.finalTime.toFixed(2);
          }
          return {
            ...shooter,
            id: index + 1,
            name: shooter.name + '',
            points: shooter.HF,
            percentage: Math.max(0, Math.round((shooter.HF / firstPlaceHF) * 10000) / 100),
            ptspercentage: Math.max(0, ((shooter.rawPts / currentStage.maximumStagePoints) * 100).toFixed(2)),
            finalTime: finalTime,
            totalHits: calculateTotalScoring(shooter)
          };
        });
      }
      else {
        const filteredData = recalculatedData.filter(entry => entry.finalTime !== 0.00);
        sortedData = filteredData.sort((a, b) => b.HF - a.HF);
        const firstPlaceHF = sortedData[0].HF;
        recalculatedData = sortedData.map((shooter, index) => {
          const finalTimeType = typeof shooter.finalTime;
          let finalTime = 'N/A';

          if (finalTimeType === 'string') {
            const finalTimeAsNumber = parseFloat(shooter.finalTime);
            if (!isNaN(finalTimeAsNumber)) {
              finalTime = finalTimeAsNumber.toFixed(2);
            }
          } else if (finalTimeType === 'number') {
            finalTime = shooter.finalTime.toFixed(2);
          }
          if (finalTime === 0.00) {
            return;
          }
          return {
            ...shooter,
            id: index + 1,
            name: shooter.name + '',
            points: Math.max(0, Math.round((shooter.HF / firstPlaceHF) * currentStage.maximumStagePoints * 10000) / 10000).toFixed(4),
            percentage: Math.max(0, Math.round((shooter.HF / firstPlaceHF) * 10000) / 100),
            ptspercentage: Math.max(0, ((shooter.rawPts / currentStage.maximumStagePoints) * 100).toFixed(2)),
            finalTime: finalTime,
            totalHits: calculateTotalScoring(shooter)
          };
        });
      }

    } else {
      const sortedData = recalculatedData.sort((a, b) => b.points - a.points);
      const firstPlace = sortedData[0].points;
      recalculatedData = sortedData.map((shooter, index) => {
        const pointsType = typeof shooter.points;
        let points = 'N/A';

        if (pointsType === 'string') {
          const pointsAsNumber = parseFloat(shooter.points);
          if (!isNaN(pointsAsNumber)) {
            points = pointsAsNumber.toFixed(4);
          }
        } else if (pointsType === 'number') {
          points = shooter.points.toFixed(4);
        }

        const finalTimeType = typeof shooter.finalTime;
        let finalTime = 'N/A';

        if (finalTimeType === 'string') {
          const finalTimeAsNumber = parseFloat(shooter.finalTime);
          if (!isNaN(finalTimeAsNumber)) {
            finalTime = finalTimeAsNumber.toFixed(2);
          }
        } else if (finalTimeType === 'number') {
          finalTime = shooter.finalTime.toFixed(2);
        }
        if (finalTime === 0.00) {
          return;
        }
        return {
          ...shooter,
          id: index + 1,
          name: shooter.name + '',
          points: points,
          percentage: Math.max(0, Math.round((shooter.points / firstPlace) * 10000) / 100),
          ptspercentage: Math.max(0, ((shooter.rawPts / currentStage.maximumStagePoints) * 100).toFixed(2)),
          finalTime: finalTime,
          totalHits: calculateTotalScoring(shooter)
        };
      });
    }

    if (!recalculateNamesChecked) {
      if (selectedNames.length > 0 && !filterout) {
        const selectedIDsArray = selectedNames.map(({ id }) => id);
        recalculatedData = recalculatedData.filter(shooter =>
          selectedIDsArray.includes(shooter.shooterID)
        );
      }
      else if (selectedNames.length > 0) {
        const selectedIDsArray = selectedNames.map(({ id }) => id);
        recalculatedData = recalculatedData.filter(shooter =>
          !selectedIDsArray.includes(shooter.shooterID)
        );
      }
    }
    if (!recalculate_Checked) {
      if (selected_Filters.division.length > 0) {
        recalculatedData = recalculatedData.filter(shooter => selected_Filters.division.includes(shooter.division));
      }

      if (selected_Filters.class.length > 0) {
        recalculatedData = recalculatedData.filter(shooter => selected_Filters.class.includes(shooter.classification));
      }

      if (selected_Filters.categories.length > 0) {
        recalculatedData = recalculatedData.filter(shooter =>
          shooter.categories.some(category => selected_Filters.categories.includes(category))
        );
      }
    }

    return recalculatedData;
  };

  const handleStageSelection = (e) => {
    const selectedStageNumber = parseInt(e.target.value, 10);
    const selectedStage = formattedMatch.stages.find(stage => stage.stageNumber === selectedStageNumber || e.target.value === 'Overall');
    setCurrentStage(selectedStage);
    if (selectedNames.length > 0) {
      const recalculatedData = applyNameFilters(selectedNames, selectedStage, recalculateNamesChecked, filterout);
      setTransformedStageData(recalculatedData);
    }
  };

  const mobileColumns = [
    { field: 'placementAndPercentage', headerName: '# / %', flex: 1, width: 75, align: 'center' },
    {
      field: 'name', headerName: 'Name', flex: 1, width: 750, align: 'center'
      // ,renderCell: (params) => (
      //   <Button
      //     variant="text"
      //     color="primary"
      //     onClick={(e) => {
      //       e.stopPropagation();
      //       window.open('https://www.google.com', '_blank');
      //     }}
      //   >
      //     {params.value}
      //   </Button>
      // )
    },
    { field: 'details', headerName: 'Points', width: 100, align: 'center' },
  ];


  const formatPlacementAndPercentage = (row) => {
    return `#${row.id} / ${row.percentage}%`;
  };

  const formatDetails = (row) => {
    return `${row.points}`;
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const calculateLetterOccurrences = (scoring) => {
    const letterCounts = { A: 0, C: 0, D: 0, M: 0, NS: 0, NPM: 0 };
    scoring.forEach(score => {
      const letters = score.split('');


      for (let i = 0; i < letters.length; i++) {
        const letter = letters[i];
        if (letter === 'N' && letters[i + 1] === 'S') {
          i++;
          letterCounts['NS']++;
        } else if (letter === 'Z') {
          letterCounts['M']++;
        } else if (letter === 'V') {
          letterCounts['A']++;
        }
        else if (letter === 'M' & letters[i - 1] !== 'P') {
          letterCounts['M']++;
        }
        else if (letter === 'P') {
          letterCounts['NPM']++;
        } else if (['A', 'C', 'D'].includes(letter)) {
          letterCounts[letter]++;
        }
      }
    });

    return letterCounts;
  };

  const formatScoring = (scoring) => {
    if (showDetailedScoring || currentStage.stageNumber === "Overall") {
      const letterOccurrences = calculateLetterOccurrences(scoring);
      const letterOccurrencesArray = Object.entries(letterOccurrences);

      const renderedOccurrences = letterOccurrencesArray.map(([letter, count]) => (
        <div key={letter}>{`${letter}: ${count}`}</div>
      ));

      return <div>{renderedOccurrences}</div>;

    }
    return scoring.map((score, index) => {
      const formattedScore = score.replace(/(NS|NPM)/g, ' $1');
      return <div key={index}>{`T${index + 1}: ${formattedScore}`}</div>;

    });
  };

  const formatScoringPer = (scoring, letter) => {
    const letterOccurrences = calculateLetterOccurrences(scoring);
    const count = letterOccurrences[letter] || 0;
    return count;
  };


  const formatProcedurals = (procedurals) => {
    return procedurals.map((procedural) => {
      if (procedural.value !== 0) {
        return (
          <div key={procedural.name}>
            {procedural.name} ({procedural.value}x)
          </div>
        );
      }
      return null;
    }).filter(Boolean);
  };


  const calculateProceduralsSum = (procedurals) => {
    const sum = procedurals.reduce((total, procedural) => total + procedural.value, 0);
    return sum;
  };


  const handleSaveNameFilters = (selected_Names, recalculateNames_Checked, filterout) => {
    setSelectedNames(selected_Names);
    setRecalculateNamesChecked(recalculateNames_Checked);
    const recalculatedData = applyNameFilters(selected_Names, currentStage, recalculateNames_Checked, filterout);
    //we need to remove the person
    setTransformedStageData(recalculatedData);

  };

  const handleOverallEdits = (stagesSelected) => {
    const overallStageIndex = formattedMatch.stages.findIndex(stage => stage.stageNumber === 'Overall');
    if (overallStageIndex !== -1) {
      const filteredStages = formattedMatch.stages.filter(stage => !stagesSelected.includes(stage.stageNumber));
      const overallStage = calculateOverallStage(filteredStages);

      const updatedStages = [
        overallStage,
        ...formattedMatch.stages.slice(1)
      ];
      const newFormattedMatch = {
        ...formattedMatch,
        stages: updatedStages
      };
      setCurrentSelectedStages(stagesSelected);
      setFormattedMatch(newFormattedMatch);
      setCurrentStage(newFormattedMatch.stages[0]);
    }
  };


  const handleSelectedFilters = (selected_Filters, recalculate_Checked) => {
    setRecalculateOptions(recalculate_Checked);
    setSelectedFilter(selected_Filters);
    const recalculatedData = applyselectedFilters(selected_Filters, currentStage, recalculate_Checked);
    setTransformedStageData(recalculatedData);
  };

  const handleResetFilters = () => {
    formattedMatch.stages.shift();
    setSelectedFilter({
      division: [],
      class: [],
      categories: [],
    });
    setSelectedNames([]);
    setRecalculateOptions(false);
    setRecalculateNamesChecked(false);
    setSearchQuery('');
    setCurrentSelectedStages([]);
    const updatedFormattedMatch = { ...uneditOverall };

    setFilterout(false);
    updatedFormattedMatch.stages.unshift(calculateOverallStage(uneditOverall.stages));
    setFormattedMatch(updatedFormattedMatch);
    setCurrentStage(updatedFormattedMatch.stages[0]);
    console.log(formattedMatchCopy);
    let recalculatedData = applyselectedFilters(selectedFilter, updatedFormattedMatch.stages[0], false);
    recalculatedData = applyNameFilters([], updatedFormattedMatch.stages[0], false, false);
    setTransformedStageData(recalculatedData);
  };

  const handleTextFieldChange = (letter, value) => {
    if (letter === 'Time') {
      value = value.replace(/[^\d]/g, '');
      if (value.length > 0) {
        value = value.slice(0, 4);
        value = value.replace(/(\d{2})(\d)/, '$1.$2');
      }
      setTotalFinalTime(value);
    } else if (letter === 'division') {
      if (disabledDivisions.includes(value)) {
        setTotalPF('MINOR');
      }
      setTotalDivision(value);
    } else if (letter === 'PF') {

      setTotalPF(value);
    } else {
      const regex = /^\d*$/;
      if (!regex.test(value)) {
        value = '0';
      } else if (value === '') {
        value = '0';
      }

      switch (letter) {
        case 'A':
          setTotalAlpha(value);
          break;
        case 'C':
          setTotalCharlie(value);
          break;
        case 'D':
          setTotalDelta(value);
          break;
        case 'M':
          setTotalMiss(value);
          break;
        case 'NS':
          setTotalNS(value);
          break;
        case 'NPM':
          setTotalNPM(value);
          break;
        case 'OTS':
          setTotalOTS(value);
          break;
        case 'P':
          setTotalProcedural(value);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (selectedRow) {
      setTotalAlpha(formatScoringPer(selectedRow.scoring, 'A'));
      setTotalCharlie(formatScoringPer(selectedRow.scoring, 'C'));
      setTotalDelta(formatScoringPer(selectedRow.scoring, 'D'));
      setTotalMiss(formatScoringPer(selectedRow.scoring, 'M'));
      setTotalNS(formatScoringPer(selectedRow.scoring, 'NS'));
      setTotalNPM(formatScoringPer(selectedRow.scoring, 'NPM'));
      setTotalFinalTime(selectedRow.finalTime);
      setTotalPF(selectedRow.powerFactor);
      setTotalDivision(selectedRow.division);
      setTotalProcedural(calculateProceduralsSum(selectedRow.procedurals));
      setTotalOTS(selectedRow.ots);
    }
  }, [selectedRow]);


  useEffect(() => {
    if (currentStage && currentStage.stageData.length > 0) {
      let recalculatedData = [...currentStage.stageData];
      recalculatedData = filterEmpty(recalculatedData);
      if (recalculateNamesChecked) {
        if (selectedNames.length > 0 && !filterout) {
          const selectedIDsArray = selectedNames.map(({ id }) => id);
          recalculatedData = recalculatedData.filter(shooter =>
            selectedIDsArray.includes(shooter.shooterID)
          );
        }
        else if (selectedNames.length > 0) {
          const selectedIDsArray = selectedNames.map(({ id }) => id);
          recalculatedData = recalculatedData.filter(shooter =>
            !selectedIDsArray.includes(shooter.shooterID)
          );
        }
        formattedMatch.stages.shift();
        formattedMatch.stages.unshift(calculateOverallStage(formattedMatch.stages, selectedNames, null));
        console.log(formattedMatch);
      }
      if (recalculateChecked) {
        if (selectedFilter.division.length > 0) {
          recalculatedData = recalculatedData.filter(shooter => selectedFilter.division.includes(shooter.division));
        }

        if (selectedFilter.class.length > 0) {
          recalculatedData = recalculatedData.filter(shooter => selectedFilter.class.includes(shooter.classification));
        }

        if (selectedFilter.categories.length > 0) {
          recalculatedData = recalculatedData.filter(shooter =>
            shooter.categories.some(category => selectedFilter.categories.includes(category))
          );
        }
        formattedMatch.stages.shift();
        formattedMatch.stages.unshift(calculateOverallStage(formattedMatch.stages, null, selectedFilter));
      }
      if (!recalculatedData || recalculatedData.length === 0) {
        return setTransformedStageData([]);
      }
      if (currentStage.stageNumber !== 'Overall') {
        let sortedData;
        if (currentStage.FixedTime) {
          sortedData = recalculatedData.sort((a, b) => b.HF - a.HF);
          const firstPlaceHF = sortedData[0].HF;
          recalculatedData = sortedData.map((shooter, index) => {
            const finalTimeType = typeof shooter.finalTime;
            let finalTime = 'N/A';

            if (finalTimeType === 'string') {
              const finalTimeAsNumber = parseFloat(shooter.finalTime);
              if (!isNaN(finalTimeAsNumber)) {
                finalTime = finalTimeAsNumber.toFixed(2);
              }
            } else if (finalTimeType === 'number') {
              finalTime = shooter.finalTime.toFixed(2);
            }
            return {
              ...shooter,
              id: index + 1,
              name: shooter.name + '',
              points: shooter.HF,
              percentage: Math.max(0, Math.round((shooter.HF / firstPlaceHF) * 10000) / 100),
              ptspercentage: Math.max(0, ((shooter.rawPts / currentStage.maximumStagePoints) * 100).toFixed(2)),
              finalTime: finalTime,
              totalHits: calculateTotalScoring(shooter)
            };
          });
        }
        else {
          const filteredData = recalculatedData.filter(entry => entry.finalTime !== 0.00);
          sortedData = filteredData.sort((a, b) => b.HF - a.HF);
          const firstPlaceHF = sortedData[0].HF;
          recalculatedData = sortedData.map((shooter, index) => {
            const finalTimeType = typeof shooter.finalTime;
            let finalTime = 'N/A';

            if (finalTimeType === 'string') {
              const finalTimeAsNumber = parseFloat(shooter.finalTime);
              if (!isNaN(finalTimeAsNumber)) {
                finalTime = finalTimeAsNumber.toFixed(2);
              }
            } else if (finalTimeType === 'number') {
              finalTime = shooter.finalTime.toFixed(2);
            }
            if (finalTime === 0.00) {
              return;
            }
            return {
              ...shooter,
              id: index + 1,
              name: shooter.name + '',
              points: Math.max(0, Math.round((shooter.HF / firstPlaceHF) * currentStage.maximumStagePoints * 10000) / 10000).toFixed(4),
              percentage: Math.max(0, Math.round((shooter.HF / firstPlaceHF) * 10000) / 100),
              ptspercentage: Math.max(0, ((shooter.rawPts / currentStage.maximumStagePoints) * 100).toFixed(2)),
              finalTime: finalTime,
              totalHits: calculateTotalScoring(shooter)
            };
          });
        }

      } else {
        const sortedData = recalculatedData.sort((a, b) => b.points - a.points);
        const firstPlace = sortedData[0].points;
        recalculatedData = sortedData.map((shooter, index) => {
          const pointsType = typeof shooter.points;
          let points = 'N/A';

          if (pointsType === 'string') {
            const pointsAsNumber = parseFloat(shooter.points);
            if (!isNaN(pointsAsNumber)) {
              points = pointsAsNumber.toFixed(4);
            }
          } else if (pointsType === 'number') {
            points = shooter.points.toFixed(4);
          }

          const finalTimeType = typeof shooter.finalTime;
          let finalTime = 'N/A';

          if (finalTimeType === 'string') {
            const finalTimeAsNumber = parseFloat(shooter.finalTime);
            if (!isNaN(finalTimeAsNumber)) {
              finalTime = finalTimeAsNumber.toFixed(2);
            }
          } else if (finalTimeType === 'number') {
            finalTime = shooter.finalTime.toFixed(2);
          }
          if (finalTime === 0.00) {
            return;
          }
          return {
            ...shooter,
            id: index + 1,
            name: shooter.name + '',
            points: points,
            percentage: Math.max(0, Math.round((shooter.points / firstPlace) * 10000) / 100),
            ptspercentage: Math.max(0, ((shooter.rawPts / currentStage.maximumStagePoints) * 100).toFixed(2)),
            finalTime: finalTime,
            totalHits: calculateTotalScoring(shooter)
          };
        });
      }

      if (!recalculateNamesChecked) {
        if (selectedNames.length > 0 && !filterout) {
          const selectedIDsArray = selectedNames.map(({ id }) => id);
          recalculatedData = recalculatedData.filter(shooter =>
            selectedIDsArray.includes(shooter.shooterID)
          );
        }
        else if (selectedNames.length > 0) {
          const selectedIDsArray = selectedNames.map(({ id }) => id);
          recalculatedData = recalculatedData.filter(shooter =>
            !selectedIDsArray.includes(shooter.shooterID)
          );
        }
      }

      if (!recalculateChecked) {
        if (selectedFilter.division.length > 0) {
          recalculatedData = recalculatedData.filter(shooter => selectedFilter.division.includes(shooter.division));
        }

        if (selectedFilter.class.length > 0) {
          recalculatedData = recalculatedData.filter(shooter => selectedFilter.class.includes(shooter.classification));
        }

        if (selectedFilter.categories.length > 0) {
          recalculatedData = recalculatedData.filter(shooter =>
            shooter.categories.some(category => selectedFilter.categories.includes(category))
          );
        }
      }
      setTransformedStageData(recalculatedData);
    }

  }, [selectedNames, currentStage, selectedFilter, formattedMatch, recalculateChecked, recalculateNamesChecked]);

  const filteredRows = transformedStageData.filter(row =>
    row.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const toggleDetailedScoring = () => {
    setShowDetailedScoring(prevState => !prevState);
  };

  const handleOpenEdit = () => {
    setIsEditOpen(true);
  };

  const handleCloseEdit = () => {
    setIsEditOpen(false);
  };

  const distributeValueRoundRobin = (procedurals, value) => {
    if (value === 0) {
      return [];
    }
    const proceduralCount = procedurals.length;
    const distributedProcedurals = procedurals.map(procedural => ({ ...procedural, value: 0 }));
    let remainingValue = value;
    let index = 0;
    while (remainingValue > 0) {
      distributedProcedurals[index].value++;
      remainingValue--;
      index = (index + 1) % proceduralCount;
    }

    return distributedProcedurals;
  };
  const distributeHitsAmongScoringPatterns = (scoringPatterns, total_Alpha, total_Charlie, total_Delta,
    total_Miss, total_NS, total_NPM) => {
    scoringPatterns = scoringPatterns.filter(pattern => !pattern.includes('NS') && !pattern.includes('NPM'));
    let modifiedPatterns = [];
    for (let i = 0; i < scoringPatterns.length; i++) {
      const pattern = scoringPatterns[i].split('');

      for (let j = 0; j < pattern.length; j++) {
        if (total_Alpha > 0) {
          pattern[j] = 'A';
          total_Alpha--;
        } else if (total_Charlie > 0) {
          pattern[j] = 'C';
          total_Charlie--;
        } else if (total_Delta > 0) {
          pattern[j] = 'D';
          total_Delta--;
        } else if (total_Miss > 0) {
          pattern[j] = 'M';
          total_Miss--;
        }
      }
      modifiedPatterns.push(pattern.join(''));

    }

    const nsConcatenated = 'NS'.repeat(total_NS);
    const npmConcatenated = 'NPM'.repeat(total_NPM);

    if (nsConcatenated) modifiedPatterns.push(nsConcatenated);
    if (npmConcatenated) modifiedPatterns.push(npmConcatenated);
    return modifiedPatterns;
  };

  const calculateHF = (timeUpdate) => {
    let points;
    if (totalPF === 'MINOR') {
      points = (totalAlpha * 5) + (totalCharlie * 3) + (totalDelta * 1) + (totalNS * -10) + (totalProcedural * -10) + (totalMiss * -10) + (totalOTS * -5);
    }
    else {
      points = (totalAlpha * 5) + (totalCharlie * 4) + (totalDelta * 2) + (totalNS * -10) + (totalProcedural * -10) + (totalMiss * -10) + (totalOTS * -5);
    }


    if (timeUpdate === 0.00) {
      return Math.max(0, points);
    }
    else {
      points = Math.max(0, points);
      const hitfactor = points / totalFinalTime;
      return hitfactor.toFixed(4);
    }


  }

  const updateShooterDivision = (selectedRow) => {
    formattedMatch.stages.forEach(stage => {
      stage.stageData.forEach(shooterData => {
        if (shooterData.USPSA_ID === selectedRow.USPSA_ID) {
          shooterData.division = totalDivision;
        }
      });
    });


  }

  const updateShooterPF = (selectedRow) => {

    formattedMatch.stages.forEach(stage => {
      stage.stageData.forEach(shooterData => {
        if (shooterData.USPSA_ID === selectedRow.USPSA_ID) {
          shooterData.powerFactor = totalPF;
          shooterData.HF = calculateHF();
        }
      });
    });
  }


  const handleSaveScore = (selectedRow) => {
    if (
      ((parseInt(totalAlpha) + parseInt(totalCharlie) + parseInt(totalDelta)
        + parseInt(totalMiss) + parseInt(totalNPM)) < parseInt(currentStage.maximumStagePoints / 5))
      || selectedRow.finalTime === 0) {

      setErrorMessage(
        <>
          You need to add {parseInt(currentStage.maximumStagePoints / 5) - (parseInt(totalAlpha) + parseInt(totalCharlie) + parseInt(totalDelta) + parseInt(totalMiss))} number<br />
          Add them to Alpha, Charlie, Delta, <br /> Miss or NPM for the total round count
        </>
      )
    }
    else if
      (
      ((parseInt(totalAlpha) + parseInt(totalCharlie) + parseInt(totalDelta)
        + parseInt(totalMiss) + parseInt(totalNPM)) > parseInt(currentStage.maximumStagePoints / 5))
      || selectedRow.finalTime === 0) {
      setErrorMessage(
        <>
          You need to remove {(parseInt(totalAlpha) + parseInt(totalCharlie) + parseInt(totalDelta) + parseInt(totalMiss))- parseInt(currentStage.maximumStagePoints / 5)} number<br />
          Add them to Alpha, Charlie, Delta, <br /> Miss or NPM for the total round count
        </>
      )
    }
    else {
      let updatedSelectedRow = selectedRow;
      if (totalDivision !== selectedRow.division) {
        updateShooterDivision(selectedRow);
        if (totalPF !== selectedRow.pf) {
          updateShooterPF(selectedRow);
        }
      }


      updatedSelectedRow.division = totalDivision;
      updatedSelectedRow.powerFactor = totalPF;
      updatedSelectedRow.finalTime = parseFloat(totalFinalTime);
      updatedSelectedRow.procedurals = distributeValueRoundRobin(selectedRow.procedurals, totalProcedural);
      updatedSelectedRow.scoring = distributeHitsAmongScoringPatterns(selectedRow.scoring, totalAlpha, totalCharlie,
        totalDelta, totalMiss, totalNS, totalNPM);
      updatedSelectedRow.HF = calculateHF(parseFloat(totalFinalTime));

      const index = formattedMatch.stages[currentStage.stageNumber].stageData.findIndex(data => data.USPSA_ID === updatedSelectedRow.USPSA_ID);

      if (index !== -1) {
        formattedMatch.stages[currentStage.stageNumber].stageData[index] = updatedSelectedRow;
      }
      formattedMatch.stages.shift();
      formattedMatch.stages.unshift(calculateOverallStage(formattedMatch.stages));
      setFormattedMatch(formattedMatch);

      let recalculatedData = [...currentStage.stageData];
      recalculatedData = filterEmpty(recalculatedData);


      recalculatedData = applyNameFilters([], currentStage, false, filterout);
      setTransformedStageData(recalculatedData);
      const reCalcIndex = recalculatedData.findIndex(data => data.USPSA_ID === updatedSelectedRow.USPSA_ID);
      selectedRow.points = parseFloat(recalculatedData[reCalcIndex].points);
      selectedRow.percentage = parseFloat(recalculatedData[reCalcIndex].percentage);
      selectedRow.id = recalculatedData[reCalcIndex].id;
      setErrorMessage('');
      handleCloseEdit();
    }

};

return (
  <div>
    <SearchMatches onSave={handleMatchSelect} />
    {formattedMatch && (
      <div>
        <h2>{formattedMatch.matchName}</h2>
        <Select value={currentStage.stageNumber} onChange={handleStageSelection}>
          <MenuItem value="" disabled>
            <em>Select a Stage</em>
          </MenuItem>
          {formattedMatch.stages
            // Sort the stages in descending order based on stage number
            .sort((b, a) => b.stageNumber - a.stageNumber)
            // Map over the sorted stages
            .map((stage, index) => (
              <MenuItem key={index} value={stage.stageNumber}>
                Stage {stage.stageNumber}
              </MenuItem>
            ))}

        </Select>
        <div>
          {currentStage && currentStage.stageData.length > 0 && (

            <>
              <FilterDialog onSave={handleSelectedFilters} selected_Filter={selectedFilter} match_type={formattedMatch.matchType.toLowerCase()} />
              <FilterNames namesList={nameList} onSave={handleSaveNameFilters} selected_names={selectedNames} />
              <FilterStages stageList={formattedMatch.stages} onSave={handleOverallEdits} currentSelectedStages={currentSelectedStages} />
              <Button onClick={handleResetFilters}>Reset Filters</Button>
            </>

          )}
          <>
            <br></br>
            <TextField
              label="Search Name"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearch}
              style={{ marginBottom: '16px' }}
            />
          </>

          {!isMobile && (
            <div style={{ height: 625, overflowX: 'auto' }}>
              <DataGrid
                rows={filteredRows}
                columns={[
                  { field: 'id', headerName: '#', width: 10, sortable: false },
                  {
                    field: 'name', headerName: 'Name', width: 150, sortable: false,

                    // ,renderCell: (params) => (
                    //   <Button
                    //     variant="text"
                    //     color="primary"
                    //     onClick={(e) => {
                    //       e.stopPropagation();
                    //       window.open('https://www.google.com', '_blank');
                    //     }}
                    //   >
                    //     {params.value}
                    //   </Button>
                    // )
                  },
                  { field: 'classification', headerName: 'Class', width: 10, sortable: false },
                  {
                    field: 'division', headerName: 'Division', width: 80, sortable: false,
                    renderCell: (params) => (
                      <Tooltip title={`Power Factor: ${params.row.powerFactor}`}>
                        <div style={{ color: params.row.powerFactor === 'MINOR' ? 'blue' : 'red' }}>
                          {params.value}
                        </div>
                      </Tooltip>
                    )
                  },
                  ...(currentStage.stageNumber !== 'Overall' ?
                    [
                      { field: 'HF', headerName: 'HF', width: 80, sortable: false },
                    ]
                    : []
                  ),
                  { field: 'finalTime', headerName: 'Time', width: 100, sortable: false },
                  { field: 'totalHits', headerName: 'Hits', width: currentStage.stageNumber !== 'Overall' ? 120 : 180, sortable: false },
                  { field: 'points', headerName: 'Points', width: 100, sortable: false },
                  {
                    field: 'percentage', headerName: 'Percentage', width: 100, sortable: false,
                    renderCell: (params) => (
                      <div>
                        {params.value}%
                      </div>
                    )
                  }
                ]}
                pageSize={5}
                disableColumnMenu
                disableColumnSelector
                disableColumnResize
                disableColumnSorting
                onRowClick={(params) => handleRowClick(params.row)}
                sx={{

                  [`& .MuiDataGrid-cell`]: {
                    paddingTop: 1,
                    paddingBottom: 1,
                    lineHeight: 'unset !important',
                    maxHeight: 'none !important',
                    whiteSpace: 'pre'
                  }
                }}
              />
            </div>
          )}

          {isMobile && (
            <div style={{ height: 500, overflowX: 'auto' }}>
              <DataGrid
                rows={filteredRows.map(row => ({
                  ...row,
                  placementAndPercentage: formatPlacementAndPercentage(row),
                  details: formatDetails(row)
                }))}
                columns={mobileColumns}
                pageSize={5}
                disableColumnMenu
                disableColumnSelector
                disableColumnResize
                disableColumnSorting
                onRowClick={(params) => handleRowClick(params.row)}
                sx={{
                  [`& .MuiDataGrid-root .MuiDataGrid-colCellTitle`]: {
                    textAlign: 'center',
                  },
                  [`& .MuiDataGrid-cell`]: {
                    paddingTop: 1,
                    paddingBottom: 1,
                    lineHeight: 'unset !important',
                    maxHeight: 'none !important',
                    whiteSpace: 'pre',
                    textAlign: 'center'
                  },
                }}
              />
            </div>
          )}

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Stage Details</DialogTitle>
            <DialogContent>
              {selectedRow && (
                <>
                  <strong>Name:</strong> {selectedRow.name} <br />
                  <strong>Place:</strong> {selectedRow.id} <br />
                  <strong>Division:</strong> {selectedRow.division} <br />
                  <strong>Class:</strong> {selectedRow.classification} <br />
                  <strong>Power Factor:</strong> {selectedRow.powerFactor} <br />
                  {currentStage.stageNumber !== "Overall" && (
                    <>
                      <strong>HF:</strong> {selectedRow.HF} <br />
                    </>
                  )}
                  <strong>Time:</strong> {selectedRow.finalTime} <br />
                  <strong>Points:</strong> {selectedRow.points} <br />
                  <strong>Points Percentage:</strong> {selectedRow.percentage + '%'} <br />
                  <strong>Total Hits:</strong> {formatScoring(selectedRow.scoring)} <br />
                  {selectedRow.procedurals.length > 0 && (
                    <>
                      <strong>Procedurals:</strong> {formatProcedurals(selectedRow.procedurals)} <br />
                    </>
                  )}
                </>
              )}
            </DialogContent>
            <DialogActions>
              {currentStage.stageNumber !== "Overall" && (
                <>
                  <Button onClick={handleOpenEdit} color="primary">
                    Edit Score
                  </Button>
                  <Button onClick={toggleDetailedScoring} color="primary">
                    {showDetailedScoring ? "More" : "Less"}
                  </Button>
                </>
              )}
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {selectedRow && (
            <>
              <Dialog open={isEditOpen} onClose={handleCloseEdit}>
                <DialogTitle>Edit Score</DialogTitle>
                <DialogContent>
                  <div>Name</div>
                  <TextField
                    variant="outlined"
                    value={selectedRow ? selectedRow.name : ''}
                    disabled
                  />
                  <br />

                  <div>Division</div>
                  <Select
                    variant="outlined"
                    value={totalDivision}
                    onChange={(e) => handleTextFieldChange('division', e.target.value)}
                  >
                    {formattedMatch.matchType.toLowerCase() === 'ipsc' && (
                      <>
                        <MenuItem value="ST">ST</MenuItem>
                        <MenuItem value="PO">PO</MenuItem>
                        <MenuItem value="CL">CL</MenuItem>
                        <MenuItem value="REV">REV</MenuItem>
                        <MenuItem value="OPEN">OPEN</MenuItem>
                        <MenuItem value="PROD">PROD</MenuItem>
                        <MenuItem value="SS">SS</MenuItem>
                      </>
                    )}
                    {formattedMatch.matchType.toLowerCase() === 'uspsa' && (
                      <>
                        <MenuItem value="CO">CO</MenuItem>
                        <MenuItem value="L">L</MenuItem>
                        <MenuItem value="L10">L10</MenuItem>
                        <MenuItem value="LO">LO</MenuItem>
                        <MenuItem value="OPEN">OPEN</MenuItem>
                        <MenuItem value="PCC">PCC</MenuItem>
                        <MenuItem value="PROD">PROD</MenuItem>
                        <MenuItem value="SS">SS</MenuItem>
                        <MenuItem value="REV">REV</MenuItem>
                      </>
                    )}
                  </Select>

                  <div>Power Factor</div>
                  <Select
                    variant="outlined"
                    value={totalPF}
                    onChange={(e) => handleTextFieldChange('PF', e.target.value)}
                  >
                    <MenuItem value="MINOR">MINOR</MenuItem>
                    <MenuItem value="MAJOR" disabled={selectedRow && disabledDivisions.includes(totalDivision)}>MAJOR</MenuItem>
                  </Select>
                  <br />
                  {selectedRow.finalTime !== 0 && (
                    <>
                      <div>Time</div>
                      <TextField
                        type="number"
                        variant="outlined"
                        value={totalFinalTime}
                        style={{ width: '100px' }}
                        onChange={(e) => handleTextFieldChange('Time', e.target.value)}
                      />
                      <br />
                    </>
                  )}
                  <div>Total Alpha</div>
                  <TextField
                    type="number"
                    variant="outlined"
                    value={totalAlpha}
                    style={{ width: '100px' }}
                    onChange={(e) => handleTextFieldChange('A', e.target.value)}
                  />
                  <br />
                  <div>Total Charlie</div>
                  <TextField
                    type="number"
                    variant="outlined"
                    value={totalCharlie}
                    style={{ width: '100px' }}
                    onChange={(e) => handleTextFieldChange('C', e.target.value)}
                  />
                  <br />
                  <div>Total Delta</div>
                  <TextField
                    type="number"
                    variant="outlined"
                    value={totalDelta}
                    style={{ width: '100px' }}
                    onChange={(e) => handleTextFieldChange('D', e.target.value)}
                  />
                  <br />
                  {parseFloat(selectedRow.finalTime) !== 0.00 ? (
                    <>
                      <div>Total Miss</div>
                      <TextField
                        type="number"
                        variant="outlined"
                        value={totalMiss}
                        style={{ width: '100px' }}
                        onChange={(e) => handleTextFieldChange('M', e.target.value)}
                      />
                      <br />
                    </>
                  ) : (
                    <>
                      <div>Total OTS</div>
                      <TextField
                        type="number"
                        variant="outlined"
                        value={totalOTS}
                        style={{ width: '100px' }}
                        onChange={(e) => handleTextFieldChange('OTS', e.target.value)}
                      />
                      <br />
                    </>
                  )}


                  <div>Total NS</div>
                  <TextField
                    type="number"
                    variant="outlined"
                    value={totalNS}
                    style={{ width: '100px' }}
                    onChange={(e) => handleTextFieldChange('NS', e.target.value)}
                  />
                  <br />
                  {(parseFloat(selectedRow.finalTime) === 0.00) && (
                    <>
                      <div>Total NPM</div>
                      <TextField
                        type="number"
                        variant="outlined"
                        value={totalNPM}
                        style={{ width: '100px' }}
                        onChange={(e) => handleTextFieldChange('NPM', e.target.value)}
                      />
                      <br />
                    </>
                  )}
                  <div>Total Procedurals</div>
                  <TextField
                    type="number"
                    variant="outlined"
                    value={totalProcedural}
                    style={{ width: '100px' }}
                    onChange={(e) => handleTextFieldChange('P', e.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseEdit} color="secondary">
                    Cancel
                  </Button>
                  <Button onClick={() => handleSaveScore(selectedRow)} color="primary">
                    Save
                  </Button>

                </DialogActions>
                {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
              </Dialog>
            </>
          )}
        </div>

      </div>
    )}
  </div>
);


};

export default Search;
