import React, { useState } from 'react';
import AddMatchButton from './AddMatchButton';
import Search from './Search';


function MatchLookupMenu() {
  const [selectedItemId, setSelectedItemId] = useState(null);


    const handleSelect = (id) => {
      setSelectedItemId(id);
    };
  
  return (
    <div>
      <AddMatchButton />
    <h1>Search for Match</h1>
    <Search onSelect={handleSelect} />
  </div>
      
  );
}

export default MatchLookupMenu;
