import React, { useState } from "react";
import './App.css';
import Selection from './components/Selection';
import StageCalc from './stageCalcComp/StageCalc';
import MatchLookupMenu from './MatchLookUp/MatchLookupMenu';
import LiveScoringMenu from './LiveScoring/LiveScoringMenu';
import Footer from "./components/Footer";
import moonIconDark from "./moon-dark.png";
import moonIconLight from "./moon-light.png";
import logo from "./Logo.png";
import Menu from './Menu';


function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const categories = ["Classifier Calculator", "Stage Calculator", "Match Results", "Live Results"];

  
  const toggleDarkMode = () => {
    setDarkMode((prevDarkMode) => !prevDarkMode);
    document.documentElement.classList.toggle('dark-mode');
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  

  return (
    <div className={`App ${darkMode ? "dark" : "light"}`}>
      <header className="App-header">
        <div className="logo-container">
          <a href="/">
            <img className="logo" src={logo} alt="Logo" />
          </a>
        </div>

      </header>
      <h3>Select a category</h3>
      <Menu categories={categories} onSelect={handleCategorySelect} />
      <div className='App'> 

      
      {selectedCategory === "Classifier Calculator" && <Selection />}
      {selectedCategory === "Stage Calculator" && <StageCalc />}
      {selectedCategory === "Match Results" && <MatchLookupMenu />}
      {selectedCategory === "Live Results" && <LiveScoringMenu/>}
        <Footer />
      </div>
    </div>
  );
}

export default App;
