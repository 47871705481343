import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Checkbox,
} from '@mui/material';

const FilterDialog = ({ onSave, selected_Filter, match_type })=> {
const [openFilter, setOpenFilter] = useState(false);
const [recalculateChecked, setRecalculateChecked] = useState(true);
const [selectedOptions, setSelectedOptions] = useState({
    division: [],
    class: [],
    categories: [],
  });

  useEffect(() => {
    setSelectedOptions(selected_Filter);
  }, [selected_Filter]);

  const handleToggle = (categories, value) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [categories]: prevSelectedOptions[categories].includes(value)
        ? prevSelectedOptions[categories].filter((val) => val !== value)
        : [...prevSelectedOptions[categories], value],
    }));
  };


  const handleApplyFilters = () => {
    onSave(selectedOptions, recalculateChecked);
    setOpenFilter(false);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  
  
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  return (
    <>

<Button

    onClick={handleOpenFilter}
    style={{
        position: 'relative',
        border: selected_Filter.division.length > 0 || selected_Filter.class.length > 0 || selected_Filter.categories.length > 0 ? '2px solid #3498db' : '2px solid transparent',
        transition: 'border-color 0.3s ease',
    }}
>
    Filter Options
</Button>

    <Dialog open={openFilter} onClose={handleCloseFilter}>
        <DialogTitle>Filter Options</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">Division</FormLabel>
              <FormGroup>
                {match_type === 'ipsc' && (
                  ['OPEN', 'PROD', 'PO', 'ST', 'CL', 'REV', 'PCC'].map((value) => (
                    <FormControlLabel
                      key={value}
                      control={
                        <Checkbox
                          checked={selectedOptions.division.includes(value)}
                          onChange={() => handleToggle('division', value)}
                          value={value}
                        />
                      }
                      label={value}
                    />
                  ))
                )}
                {match_type === 'uspsa' && (
                  ['CO', 'L', 'L10', 'LO', 'OPEN', 'PCC', 'PROD', 'SS', 'REV'].map((value) => (
                    <FormControlLabel
                      key={value}
                      control={
                        <Checkbox
                          checked={selectedOptions.division.includes(value)}
                          onChange={() => handleToggle('division', value)}
                          value={value}
                        />
                      }
                      label={value}
                    />
                  ))
                )}
              </FormGroup>
            </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Class</FormLabel>
            <FormGroup>
              {['G', 'M', 'A', 'B', 'C', 'D', 'U'].map((value) => (
                <FormControlLabel
                  key={value}
                  control={
                    <Checkbox
                      checked={selectedOptions.class.includes(value)}
                      onChange={() => handleToggle('class', value)}
                      value={value}
                    />
                  }
                  label={value}
                />
              ))}
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Category</FormLabel>
            <FormGroup>
              {['Junior', 'Senior', 'Super Senior', 'Distinguished Senior', 'Lady', 'Law Enforcement', 'Military', 'Foreign', 'Preteen'].map((value) => (
                <FormControlLabel
                  key={value}
                  control={
                    <Checkbox
                      checked={selectedOptions.categories.includes(value)}
                      onChange={() => handleToggle('categories', value)}
                      value={value}
                    />
                  }
                  label={value}
                />
              ))}
            </FormGroup>
          </FormControl>

        </DialogContent>
        <DialogActions>
          <label style={{ display: 'flex', alignItems: 'center',cursor: 'pointer'  }}>
          <Checkbox
              checked={recalculateChecked}
              onChange={(e) => setRecalculateChecked(e.target.checked)}
              color="primary"
          />Recalc Options
          </label>
            <Button onClick={handleCloseFilter} variant="outlined" color="secondary">
            Close
          </Button>
          <Button onClick={handleApplyFilters} variant="contained" color="primary">
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default FilterDialog;
