import React, { useState } from 'react';
import Popup from './Popup';
import './Popup.css';

function AddMatchButton() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
      <h2>Match Info</h2>
        <button className="popup-btn" onClick={openPopup}>Add Match Results</button>
        <Popup isOpen={isPopupOpen} onClose={closePopup} />
        
    </div>
    
  );
}

export default AddMatchButton;
