import React, { useState, useEffect } from 'react';
import { DataGrid} from '@mui/x-data-grid';
import { MenuItem, Select, Button, TextField, Tooltip, Dialog, DialogTitle, DialogContent} from '@mui/material';
import FilterDialogLS from './FilterDialogLS';
import FilterNamesLS from './FilterNamesLS';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function LiveScoringResults(selectedMatchInfo) {
  const [transformedStageData, setTransformedStageData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentStage, setCurrentStage] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [nameList, setnameList] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedNames, setSelectedNames] = useState([]);
  const [filterout, setFilterout] = useState(false);
  const [open, setOpen] = useState(false);
  const [formattedMatch, setFormattedMatch] = useState(null);
  const [currentSelectedStages, setCurrentSelectedStages] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({
    division: [],
    class: [],
    categories: []
  });
const handleStageSelection = (e) => {
  const selectedStageNumber = parseInt(e.target.value, 10);
  const selectedStage = selectedMatchInfo.MatchInfo.stages.find(stage => stage.stage_number === selectedStageNumber || e.target.value === 'Overall');
  setCurrentStage(selectedStage);
  //setTransformedStageData(recalculatedData);
  //if (selectedNames.length > 0) {
      //const recalculatedData = applyNameFilters(selectedNames, selectedStage, recalculateNamesChecked, filterout);
      //setTransformedStageData(recalculatedData);
  //}
};

const handleSelectedFilters = (selected_Filters) => {
  setSelectedFilter(selected_Filters);
  const recalculatedData = Filter(selectedNames, currentStage, selected_Filters, filterout);
  setTransformedStageData(recalculatedData);
};

const handleSaveNameFilters = (selected_Names, filter_out) => {
   setSelectedNames(selected_Names);
   setFilterout(filter_out);
   const recalculatedData = Filter(selected_Names, currentStage, selectedFilter, filter_out);
   setTransformedStageData(recalculatedData);

};

const Filter = (selected_Names, currentStage, selected_Filters, filterout) => {
  let recalculatedData = [...currentStage.stageData];
  
  if (selected_Names.length > 0 && filterout) {
    const selectedIDsArray = selected_Names.map(({ id }) => id);
    recalculatedData = recalculatedData.filter(shooter =>
        !selectedIDsArray.includes(shooter.shooter_id)
    );
  }
  else if(selected_Names.length > 0)
  {
    const selectedIDsArray = selected_Names.map(({ id }) => id);
    recalculatedData = recalculatedData.filter(shooter =>
        selectedIDsArray.includes(shooter.shooter_id)
    );
  }
  

  if (selected_Filters.division.length > 0) {
    recalculatedData = recalculatedData.filter(shooter => selectedFilter.division.includes(shooter.shooter_division));
  }
  if (selected_Filters.class.length > 0) {
      recalculatedData = recalculatedData.filter(shooter => selectedFilter.class.includes(shooter.shooter_class));
  }
  if (selected_Filters.categories.length > 0) {
    recalculatedData = recalculatedData.filter(shooter =>
      shooter.categories.some(category => selectedFilter.categories.includes(category))
    );        
  }
  return recalculatedData;
};



useEffect(() => {
  selectedMatchInfo.MatchInfo.stages.unshift(calculateOverallStage(selectedMatchInfo.MatchInfo.stages));
  setFormattedMatch(selectedMatchInfo);
  setCurrentStage(selectedMatchInfo.MatchInfo.stages[0]);
}, [selectedMatchInfo]);

useEffect(() => {
    let recalculatedData = currentStage;
    
    if (!recalculatedData || recalculatedData.length === 0) {
      return setTransformedStageData([]);
    }
    if (currentStage.stage_name !== 'Overall') {
      
      const sortedData = recalculatedData.shooters.sort((a, b) => b.HF - a.HF);
      
      const firstPlaceHF = sortedData[0].HF;
      
      recalculatedData = sortedData.map((shooter, index) => {
        let finalTime = 'N/A';
        
        if (typeof shooter.finalTime === 'string') {
          const finalTimeAsNumber = parseFloat(shooter.time);
          if (!isNaN(finalTimeAsNumber)) {
            finalTime = finalTimeAsNumber.toFixed(2);
          }
        } else if (typeof shooter.time === 'number') {
          finalTime = shooter.time.toFixed(2);
        }
        
        const points = Math.max(0, Math.round((shooter.HF / firstPlaceHF) * currentStage.stage_max_points * 10000) / 10000).toFixed(4);
        const percentage = Math.max(0, Math.round((shooter.HF / firstPlaceHF) * 10000) / 100);
        const ptspercentage = Math.max(0, ((shooter.rawPts / currentStage.stage_max_points) * 100).toFixed(2));

        let combinedScore = '';
        for (const key in shooter.score) {
          if (shooter.score.hasOwnProperty(key) && shooter.score[key] > 0) {
            combinedScore += `${key}: ${shooter.score[key]} `;
          }
        }

        
        return {
          ...shooter,
          id: index + 1,
          name: shooter.shooter_name + '',
          division: shooter.shooter_division,
          totalHits: combinedScore,
          points: points,
          percentage: percentage,
          ptspercentage: ptspercentage,
          finalTime: finalTime
        };
      });
    } else {
      const sortedData = recalculatedData.stageData.sort((a, b) => b.points - a.points);
      const firstPlace = sortedData[0].points;

      recalculatedData = sortedData.map((shooter, index) => {
        let points = 'N/A';
        const pointsType = typeof shooter.points;
        
        if (pointsType === 'string') {
          const pointsAsNumber = parseFloat(shooter.points);
          if (!isNaN(pointsAsNumber)) {
            points = pointsAsNumber.toFixed(4);
          }
        } else if (pointsType === 'number') {
          points = shooter.points.toFixed(4);
        }
        
        let finalTime = 'N/A';
        const finalTimeType = typeof shooter.finalTime;
        
        if (finalTimeType === 'string') {
          const finalTimeAsNumber = parseFloat(shooter.finalTime);
          if (!isNaN(finalTimeAsNumber)) {
            finalTime = finalTimeAsNumber.toFixed(2);
          }
        } else if (finalTimeType === 'number') {
          finalTime = shooter.finalTime.toFixed(2);
        }
        
        const percentage = Math.max(0, Math.round((shooter.points / firstPlace) * 10000) / 100);
        const ptspercentage = Math.max(0, ((shooter.rawPts / currentStage.maximumStagePoints) * 100).toFixed(2));
        
        //console.log(recalculatedData);

        let combinedScore = '';
        for (const key in shooter.score) {
          if (shooter.score.hasOwnProperty(key) && shooter.score[key] > 0) {
            combinedScore += `${key}: ${shooter.score[key]} `;
          }
        }

        return {
          ...shooter,
          id: index + 1,
          name: shooter.shooter_name + '',
          division: shooter.shooter_division,
          points: points,
          totalHits: combinedScore,
          percentage: percentage,
          ptspercentage: ptspercentage,
          finalTime: finalTime
        };
      });
    }

    if(selectedNames.length > 0 && !filterout)
    {
      const selectedIDsArray = selectedNames.map(({ id }) => id);
      recalculatedData = recalculatedData.filter(shooter =>
          selectedIDsArray.includes(shooter.shooter_id)
      );
    }
    else if(selectedNames.length > 0)
    {
      const selectedIDsArray = selectedNames.map(({ id }) => id);
      recalculatedData = recalculatedData.filter(shooter =>
          !selectedIDsArray.includes(shooter.shooter_id)
      );
    }

    if (selectedFilter.division.length > 0) {
      recalculatedData = recalculatedData.filter(shooter => selectedFilter.division.includes(shooter.shooter_division));
    }

    if (selectedFilter.class.length > 0) {
        recalculatedData = recalculatedData.filter(shooter => selectedFilter.class.includes(shooter.shooter_class));
    }

    if (selectedFilter.categories.length > 0) {
      recalculatedData = recalculatedData.filter(shooter =>
        shooter.categories.some(category => selectedFilter.categories.includes(category))
      );        
    }
    setTransformedStageData(recalculatedData);
  }, [selectedNames, currentStage, selectedFilter, formattedMatch]);

const mobileColumns = [
  { field: 'placementAndPercentage', headerName: '# / %', flex: 1, width: 75, align: 'center'},
  { field: 'name', headerName: 'Name', flex: 1, width: 750, align: 'center'
  // ,renderCell: (params) => (
  //   <Button
  //     variant="text"
  //     color="primary"
  //     onClick={(e) => {
  //       e.stopPropagation();
  //       window.open('https://www.google.com', '_blank');
  //     }}
  //   >
  //     {params.value}
  //   </Button>
  // )
 },
  { field: 'details', headerName: 'Points', width: 100, align: 'center' },
];

const handleSearch = (event) => {
  setSearchQuery(event.target.value);
};

const filteredRows = transformedStageData.filter(row =>
  row.name?.toLowerCase().includes(searchQuery.toLowerCase())
);




const calculateOverallStage = (stages) => {
  let overallData = {};
  let sumMaxPoints = 0;
  stages.forEach(stage => {

    if (stage.stage_number !== 'Overall') {
      const highestHF = Math.max(...stage.shooters.map(shooter => shooter.HF));
      stage.shooters.forEach(shooter => {
        if (!overallData[shooter.shooter_id]) {
          overallData[shooter.shooter_id] = { ...shooter, points: 0, finalTime: 0};
          nameList[shooter.shooter_id] = shooter.shooter_name;
        }
        Object.keys(shooter.score).forEach(category => {
          // Check if the value is a number (exclude non-numeric properties)
          if (!isNaN(shooter.score[category])) {
            // Add the score to the corresponding category total
            overallData[shooter.shooter_id].score[category] += shooter.score[category];
          }
        });

        const pointsEarned = (stage.stage_max_points * (shooter.HF / highestHF)).toFixed(4);
        overallData[shooter.shooter_id].points += parseFloat(pointsEarned);
        overallData[shooter.shooter_id].finalTime += parseFloat(shooter.time);
      });


      sumMaxPoints += stage.stage_max_points;
    }
  });
  return {
    stage_number: 'Overall',
    stage_name: 'Overall',
    maximumStagePoints: sumMaxPoints,
    stageData: Object.values(overallData).map(shooter => ({
      ...shooter
    }))
  };
  
};

const handleResetFilters = () => {
  setSelectedFilter({
      division: [],
      class: [],
      categories: [],
  });
  setSelectedNames([]);
  setSearchQuery('');
  setCurrentStage(selectedMatchInfo.MatchInfo.stages[0]);
};

const formatPlacementAndPercentage = (row) => {
  return `#${row.id} / ${row.percentage}%`;
};

const formatDetails = (row) => {
  return `${row.points}`;
};

const handleRowClick = (row) => {
  setSelectedRow(row);
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};

  //do not recalculate the HF. This is for showing the results with live updates
  //What to do to win the stage and get the required points
  /*
        
      
      
      */
  return (
  <div>
    {formattedMatch &&
    (
    <div>
      <h3>Current Stage</h3>
      <Select value={currentStage.stage_number} onChange={handleStageSelection}>
        <MenuItem value="" disabled>
          <em>Select a Stage</em>
        </MenuItem>
        {formattedMatch.MatchInfo.stages.map((stage, index) => (
          <MenuItem key={index} value={stage.stage_number}>
            {stage.stage_name}
          </MenuItem>
        ))}
      </Select>
      <br/>
      <FilterDialogLS onSave={handleSelectedFilters} selected_Filter={selectedFilter} />
      <FilterNamesLS namesList={nameList} onSave={handleSaveNameFilters} selected_names={selectedNames}/>
      <Button onClick={handleResetFilters}>Reset Filters</Button>
      <br/>
      <TextField
        label="Search Name"
        variant="outlined"
        value={searchQuery}
        onChange={handleSearch}
        style={{ marginBottom: '16px' }}
      />

      <div style={{ height: 400, overflowX: 'auto' }}>
        {!isMobile && (
          <DataGrid
            rows={filteredRows}
            columns={[
              { field: 'id', headerName: '#', width: 10, sortable: false },
              { field: 'name', headerName: 'Name', width: 150, sortable: false },
              { 
                field: 'division', 
                headerName: 'Division', 
                width: 80, 
                sortable: false,
                renderCell: (params) => (
                  <Tooltip title={`Power Factor: ${params.row.power_factor}`}>
                    <div style={{ color: params.row.power_factor === 'MINOR' ? 'blue' : 'red' }}>
                      {params.value}
                    </div>
                  </Tooltip>
                ) 
              },
              ...(currentStage.stage_number !== 'Overall' ?
              [
                { field: 'HF', headerName: 'HF', width: 80, sortable: false },
              ]
              : []
            ),
              { field: 'finalTime', headerName: 'Time', width: 100, sortable: false },
              { field: 'totalHits', headerName: 'Hits', width:  120, sortable: false },
              { field: 'points', headerName: 'Points', width: 100, sortable: false },
              { 
                field: 'percentage', 
                headerName: 'Percentage', 
                width: 100, 
                sortable: false,          
                renderCell: (params) => (
                  <div>
                    {params.value}%
                  </div>
                ) 
              }
            ]}
            pageSize={5}
            disableColumnMenu
            disableColumnSelector
            disableColumnResize
            disableColumnSorting
            onRowClick={(params) => handleRowClick(params.row)}
            sx={{
              [`& .MuiDataGrid-cell`]: {
                paddingTop: 1,
                paddingBottom: 1,
                lineHeight: 'unset !important',
                maxHeight: 'none !important',
                whiteSpace: 'pre'
              }
            }}
          />
        )}

        {isMobile && (
          <DataGrid
            rows={filteredRows.map(row => ({
              ...row,
              placementAndPercentage: formatPlacementAndPercentage(row),
              details: formatDetails(row)
            }))}
            columns={mobileColumns}
            pageSize={5}
            disableColumnMenu
            disableColumnSelector
            disableColumnResize
            disableColumnSorting
            onRowClick={(params) => handleRowClick(params.row)}
            sx={{
              [`& .MuiDataGrid-root .MuiDataGrid-colCellTitle`]: {
                textAlign: 'center',
              },
              [`& .MuiDataGrid-cell`]: {
                paddingTop: 1,
                paddingBottom: 1,
                lineHeight: 'unset !important',
                maxHeight: 'none !important',
                whiteSpace: 'pre',
                textAlign: 'center'
              },
            }}
          />
        )}

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Stage Details</DialogTitle>
            <DialogContent>
            {selectedRow &&
            (
            <>
            <strong>Name:</strong> {selectedRow.shooter_name} <br />
            <strong>Place:</strong> {selectedRow.id} <br />
            <strong>Division:</strong> {selectedRow.division} <br />
            <strong>Power Factor:</strong> {selectedRow.power_factor} <br />
            {currentStage.stage_name !== "Overall" && (
              <>
              <strong>HF:</strong> {selectedRow.HF} <br />
              </>
            )}
            <strong>Time:</strong> {selectedRow.finalTime} <br />
            <strong>Points:</strong> {selectedRow.points} <br />
            <strong>Points Percentage:</strong> {selectedRow.percentage}% <br />
            <strong>Total Hits:</strong> <div>{selectedRow.totalHits}</div> <br />
            </>
            )}
          </DialogContent>
        </Dialog>
      </div>
    </div>

    )}

    </div>
  );
}

export default LiveScoringResults;
