import React, { useState, useEffect } from "react";
import TableContainer from '@mui/material/TableContainer';
import { DataGrid } from '@mui/x-data-grid';
import "./Hitfactor.css";

const Hitfactor = ({ selectedDivision, selectedClassifier }) => {
  const [data, setData] = useState(null); 
  const [updatedRows, setUpdatedRows] = useState([]);
  

  const fetchData = async (division, classifier) => {
    
    try {
      const response = await fetch(`https://onlyhf.com/api/classificationPercentage?division=${division}&classifier=${classifier}`);
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  function createData(Class, id) {
    return { id, Class, MinHF: '' }; // Add MinHF property with initial empty value
  }

  const rows = [
    createData('100% - HHF', 1),
    createData('95% - GM', 2),
    createData('85% - M', 3),
    createData('75% - A', 4),
    createData('60% - B', 5),
    createData('40% - C', 6),
    createData('2% - D', 7),
  ];



  const columns = [
    { field: 'Class', headerName: 'Class'},
    { field: 'MinHF', headerName: 'Min HF'},
  ];

  useEffect(() => {
    if (selectedDivision && selectedClassifier) {
      fetchData(selectedDivision, selectedClassifier);
    }
  }, [selectedDivision, selectedClassifier]);
  
  useEffect(() => {
    if (data) {
      const updatedRowsVar = rows.map((row, index) => ({
        ...row,
        MinHF: data[0][index] !== undefined ? data[0][index] : ''
      }));
      setUpdatedRows(updatedRowsVar);
    }
  }, [data]);

  return (

    <div className='HF'>
      <h2>Classification Percentage</h2>
      <TableContainer className='MinHFtable'>
      <div style={{ width: '225px', overflowX: 'auto', margin: '0 auto' }}>
        <DataGrid
          rows={updatedRows}
          columns={columns}
          autoHeight
          pageSizeOptions={[]}
          disableRowCount
          disableColumnSorting
          disableColumnMenu
          disableColumnSelector
          disableColumnResize
          disableColumnReorder
          disableSelectionOnClick
          hideFooter 
        />
        </div>
      </TableContainer>
    </div>

  );
};

export default Hitfactor