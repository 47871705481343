import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Checkbox
} from '@mui/material';

const FilterStages= ({stageList, onSave, currentSelectedStages}) => {
  const [openFilter, setOpenFilter] = useState(false);
  const initialSelectedStages = stageList.reduce((acc, stage) => {
    acc[stage.stageNumber] = stage.stageNumber === 'Overall';
    return acc;
  }, {});

  const [selectedStages, setSelectedStages] = useState(initialSelectedStages);
  const [errorMessage, setErrorMessage] = useState('');

  const handleStageToggle = (stageNumber) => {
    if (stageNumber !== 'Overall') {
      setSelectedStages(prevSelectedStages => ({
        ...prevSelectedStages,
        [stageNumber]: !prevSelectedStages[stageNumber]
      }));
    }
  };

  const handleApplyFilters = () => {
    const selectedStagesToSend = Object.keys(selectedStages)
      .filter(stageNumber => selectedStages[stageNumber] && stageNumber !== 'Overall')
      .map(Number);
      if (selectedStagesToSend.length !== stageList.length) {
        onSave(selectedStagesToSend);
        setOpenFilter(false);
      } else {
        setErrorMessage('Please keep atleast one stage.');
      }
  };
  
  
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  return (
    <>
      <Button
          onClick={handleOpenFilter}
          style={{
            position: 'relative',
            border: currentSelectedStages.length > 0 ? '2px solid #3498db' : '2px solid transparent',
            transition: 'border-color 0.3s ease',
        }}
      >
          Overall Stages
      </Button>

      <Dialog
        open={openFilter}
        PaperProps={{ style: { minHeight: '450px', maxHeight: '450px' } }}
        onClose={handleCloseFilter}
      >
        <DialogTitle>Select Stage to Filter Out</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">Stage Numbers</FormLabel>
            <FormGroup>
              {stageList && stageList
                .map((stageObject, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={selectedStages[stageObject.stageNumber]}
                        onChange={() => handleStageToggle(stageObject.stageNumber)}
                        disabled={stageObject.stageNumber === 'Overall'} // Disable Overall stage checkbox
                      />
                    }
                    label={`Stage ${stageObject.stageNumber}`}
                  />
                ))
              }
            </FormGroup>
            {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFilter} variant="outlined" color="secondary">
            Close
          </Button>
          <Button onClick={handleApplyFilters} variant="contained" color="primary">
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default FilterStages;
