import React, { useState } from 'react';
import "./Footer.css";

const Footer = () => {
  const [copiedMessage, setCopiedMessage] = useState('');

  const handleCopyEmail = (event) => {
    event.preventDefault();
    const email = 'onlyhf.bryan@gmail.com';
    navigator.clipboard.writeText(email);
    setCopiedMessage('Email copied to clipboard!');
    setTimeout(() => {
      setCopiedMessage('');
    }, 3000);
  };



  return (
    
    <div className="footer">
      <br/>
      <div>
        <a href="/about.html" className="footer-link">
          About Me
        </a>
      </div>
      <div>
        {copiedMessage && <span>{copiedMessage}</span>}
        <a href="#copy" className="footer-link" onClick={handleCopyEmail}>
        onlyhitfactor@gmail.com
        </a>
      </div>
      <div>
        <a href="https://www.instagram.com/bryanchau0/?hl=en" className="footer-link">
          My Instagram
        </a>
      </div>
    </div>
  );
};

export default Footer;
