import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  TextField,
  Radio,
  RadioGroup,
  Autocomplete
} from '@mui/material';

const SearchMatches = ({ onSave }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [matchList, setMatchList] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState('name');
  const [searchText, setSearchText] = useState('');
  const [selectedResult, setSelectedResult] = useState(null);
  const [errorText, setErrorText] = useState('');
  const [errorText2, setErrorText2] = useState('');

  const handleMatchSelect = (matchID) => {
    onSave(matchID);
    setOpenFilter(false);
  };

  const handleChange = (event) => {
    setSearchCriteria(event.target.value);
  };
  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };


  const handleSearch = async (SearchCrit, SearchText) => {
    if (SearchText.trim() !== '' || SearchText === null) {
      try {
        const response = await fetch(`https://onlyhf.com:5000/api/matchdata?Type=${SearchCrit}&SearchText=${SearchText}`);
        const data = await response.json();
        if (Array.isArray(data)) {
          setMatchList(data)
          setSelectedResult(null)
          setErrorText('');
        } else {
          console.error('Error fetching options: Invalid response data format');
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    }
    else {
      setErrorText("Enter a value to search");
    }
    ;

  }


  useEffect(() => {
    setSelectedResult(selectedResult);
  }, [selectedResult]);

  return (
    <>
      <Button onClick={handleOpenFilter} style={{ position: 'relative' }}>
        Search for Matches
      </Button>


      <Dialog
        open={openFilter}
        PaperProps={{ style: { maxHeight: '550px' } }}
        onClose={handleCloseFilter}
      >
        <DialogTitle>Search for Matches</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="searchCriteria"
              name="searchCriteria"
              value={searchCriteria}
              onChange={handleChange}
            >
              <FormControlLabel value="name" control={<Radio />} label="Name" />
              <FormControlLabel value="uspsa_id" control={<Radio />} label="USPSA Number" />
              <FormControlLabel value="match_name" control={<Radio />} label="Match Name" />
            </RadioGroup>
            <TextField
              variant="outlined"
              value={searchText}
              onChange={handleSearchTextChange}
              margin="normal"
              fullWidth
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch(searchCriteria, searchText)
                }}}
            />
            <Button onClick={() => handleSearch(searchCriteria, searchText)}>Search</Button>
            {errorText && <div style={{ color: 'red' }}>{errorText}</div>}
            <br />
            {matchList.length > 0 && (
              <>
                <Autocomplete
                  value={selectedResult}
                  onChange={(event, newValue) => {
                    setSelectedResult(newValue);
                  }}
                  options={matchList}
                  getOptionLabel={(option) => option[0]}
                  renderInput={(params) => (
                    <TextField {...params} value="" label="Search Match Results" variant="outlined" fullWidth />
                  )}

                />
                <br />
                <Button
                  onClick={() => {
                    if (selectedResult && selectedResult[1]) {
                      handleMatchSelect(selectedResult[1]);
                      setErrorText2('');
                    } else {
                      // Handle the error, such as displaying a message to the user
                      setErrorText2("Please select an option before clicking the button.");
                      
                    }
                  }}

                  variant="contained"
                  color="primary"

                >
                  Select Match
                </Button>
                {errorText2 && <div style={{ color: 'red' }}>{errorText2}</div>}
              </>
            )}
            <DialogActions>

            </DialogActions>
          </FormControl>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFilter} variant="outlined" color="secondary">
            Close
          </Button>

        </DialogActions>
      </Dialog>
    </>
  );
};
export default SearchMatches;
