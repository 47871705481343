
import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Checkbox,
  TextField
} from '@mui/material';

const FilterNamesLS= ({namesList, onSave, selected_names }) => {
  const [selectedNames, setSelectedNames] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [recalculateNameChecked, setRecalculateNameChecked] = useState(false);
  const [filterout, setFilterout] = useState(false);
  const [nameList, setnameList] = useState({});
  

  const handleToggle = (USPSA_ID, name) => {
    setSelectedNames(prevSelectedNames => {
      const isSelected = prevSelectedNames.some(item => item.id === USPSA_ID);
      if (isSelected) {
        return prevSelectedNames.filter(item => item.id !== USPSA_ID);
      } else {
        return [...prevSelectedNames, { id: USPSA_ID, name: name }];
      }
    });
  };

  useEffect(() => {
    setSelectedNames(selected_names);
  }, [selected_names]);

  const handleApplyFilters = () => {
    onSave(selectedNames, filterout);
    setOpenFilter(false);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  return (
    <>
<Button onClick={handleOpenFilter} style={{ position: 'relative' }}>
    Filter Names
    {selected_names.length > 0 && (
        <span style={{
            marginLeft: '5px', // Adjust the spacing between the text and the indicator
            backgroundColor: 'red',
            color: 'white',
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            {selected_names.length}
        </span>
    )}
</Button>


    <Dialog
  open={openFilter}
  PaperProps={{ style: { minHeight: '450px', maxHeight: '450px' } }}
  onClose={handleCloseFilter}
>
        <DialogTitle>Select Names to Filter</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">Names</FormLabel>
            <TextField
              label="Search Name"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearch}
              style={{ marginBottom: '16px' }}
            />

            <FormGroup>
            {Object.entries(namesList)
              .map(([shooterID, name]) => ({ shooterID, name }))
              .sort((a, b) => {
                const lastNameA = a.name.split(' ').pop();
                const lastNameB = b.name.split(' ').pop();
                return lastNameA.localeCompare(lastNameB);
              })
              .filter(({ name }) => name.toLowerCase().includes(searchQuery.toLowerCase()))
              .map(({ shooterID, name }) => (
                <FormControlLabel
                  key={shooterID}
                  control={
                    <Checkbox
                      checked={selectedNames.some(item => item.id === shooterID)}
                      onChange={() => handleToggle(shooterID, name)}
                      value={shooterID}
                    />
                  }
                  label={name}
                />
              ))
            }


            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <label style={{ display: 'flex', alignItems: 'center',cursor: 'pointer'  }}>
          <Checkbox
              checked={filterout}
              onChange={(e) => setFilterout(e.target.checked)}
              color="primary"
            />Filterout
            </label>
          <Button onClick={handleCloseFilter} variant="outlined" color="secondary">
            Close
          </Button>
          <Button onClick={handleApplyFilters} variant="contained" color="primary">
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default FilterNamesLS;
