import React, { useState, useEffect } from "react";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import "./HitfactorPercentcalc.css"
import MajorCalculation from './MajorCalculation';
import MinorCalculation from './MinorCalculation';
import { DataGrid } from '@mui/x-data-grid';
import "./Hitfactor.css";

const HitfactorPercentcalc = ({ selectedDivision, selectedClassifier, percentage, minorMajor, clickedCalculate, setClickedCalculate, setMinorMajor }) => {
  const [data, setData] = useState([]);
  const [columnNames, setColumnNames] = useState(["Time", "Steel", "Alpha", "Charlie", "Delta"]);
  const [currentPage, setCurrentPage] = useState(1);
  const minorDivisions = ["Production", "Carry Optics", "PCC", "Limited Optics"];
  const itemsPerPage = 10; // Number of items to display per page
  const pageCount = Math.ceil(data.length / itemsPerPage);

  const fetchData = async (division, classifier) => {
    try {
      if (!clickedCalculate) {
        return;
      }
      const response = await fetch(`https://onlyhf.com:5000/api/classificationinfo?division=${division}&classifier=${classifier}`);
      const rawData = await response.json();
      // Perform calculations on the rawData before setting it in the state
      let calculatedData = "";
      if (minorMajor === 'Minor' || minorDivisions.includes(selectedDivision)) {
        calculatedData = MinorCalculation({ rawData, percentage });
        minorMajor = 'Minor';
      }
      else if (minorMajor === 'Major') {
        calculatedData = MajorCalculation({ rawData, percentage });
      }
      if (calculatedData !== "") {
        setData(calculatedData);
      }
      else {
        console.error('Error calculating data!');
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // Check if the calculate button is clicked and perform the data fetch accordingly
    if (clickedCalculate && selectedClassifier && percentage !== "") {
      fetchData(selectedDivision, selectedClassifier, percentage, minorMajor);
      setClickedCalculate(false); // Reset the clickedCalculate flag after triggering the calculation
    }

    if (minorDivisions.includes(selectedDivision)) {
      setMinorMajor("Minor");
    }
  }, [clickedCalculate, selectedDivision, selectedClassifier, percentage, minorMajor]);




  useEffect(() => {
    if (data.length > 0) {
      const innerArrayLength = data[0].length;
      if (innerArrayLength === 5) {
        setColumnNames(["Time", "Steel", "Alpha", "Charlie", "Delta"]);
      } else if (innerArrayLength === 3) {
        setColumnNames(["Alpha", "Charlie", "Delta"]);
      }
    }
  }, [data]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = data.slice(startIndex, endIndex);

  const rows = displayedData.map((row, index) => ({
    id: index,
    ...row.reduce((acc, value, colIndex) => {
      acc[columnNames[colIndex]] = value || "0";
      return acc;
    }, {}),
  }));

  // Define columns for the DataGrid
  const columns = columnNames.map((columnName, index) => ({
    field: columnName,
    headerName: columnName,
    align: 'center',
    sortable: false,
    flex: 1,
  }));


  return (
    <div className='BestCaseScenario'>
      <h2 >Best Case Scenarios - ({minorMajor})</h2>
      <div>
        <div style={{ width: '350px', overflowX: 'auto', margin: '0 auto' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={itemsPerPage}
            page={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            disableColumnMenu
            disableColumnSelector
            disableColumnResize
            rowsPerPageOptions={[]}
            pagination
            hideFooter
          />
        </div>
        <Stack
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            color="primary"
            shape="rounded"
          />
        </Stack>
      </div>
    </div>
  );
};

export default HitfactorPercentcalc