import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Button  } from '@mui/material';
import LiveScoringResults from './LiveScoringResults';
import axios from 'axios'; 

const LiveScoringMenu = () =>  {
  const [selectedMatchInfo, setSelectedMatchInfo] = useState(null);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [options, setOptions] = useState([]);
  const [outputMessage, setOutputMessage] = useState('');
  const [matchCount, setMatchCount] = useState('');
    useEffect(() => {
      fetchData();
    }, []);
  

    const fetchData = async () => {
      try {
        const response = await fetch('https://onlyhf.com:5000/api/livescoring');
        const data = await response.json();
        if (Array.isArray(data)) {
          if(data.length !== 0)
          {
            setOptions(transformData(data));
            setMatchCount(2);
            setOutputMessage('');
          }
          else {
            setOutputMessage('No live results are currently available. 😢');
          }
        } 
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    const handleMatchSelect = async (id) => {
      setSelectedMatch(id.match_id);
      const input_ID = id.match_id
      try {
        const response = await fetch(`https://onlyhf.com:5000/api/livescoringresults?matchid=${input_ID}`);
        if (!response.ok) {
          throw new Error('Failed to load JSON data');
        }
        const jsonDataString = await response.text();
        const jsonData = JSON.parse(jsonDataString);
          setSelectedMatchInfo(jsonData);
      } catch (error) {
        console.error(error);
      }
      
    };

    const transformData = (data) => {
      return data.map((item) => ({
        match_id: item[0],
        name: item[1],
        value: `${item[0]} ${item[1]}`,
      }));
    };


    useEffect(() => {
      if(selectedMatch !== null)
      {
        const intervalId = setInterval(() => {
          axios.get('https://onlyhf.com:5000/api/livescoringupdate', {
            params: {
              id: selectedMatch,
              number: matchCount
            }
          })
          .then(response => {
            handleMatchSelect(selectedMatch);
          })
          .catch(error => {
  
          });
        }, 60000); // Fetch data every 60000 milliseconds (1 minute)
      
        return () => clearInterval(intervalId); // Clear the interval when the component unmounts
      }

    }, []);
    
  
  return (
    <div>
    <h2>Current Matches</h2>
      <div>
      {options.length === 0 ? (
        <h3>{outputMessage}</h3>
      ) : (
        options.map((item) => (
          <Button 
            key={item.match_id} 
            variant={selectedMatch === item.match_id ? "contained" : "outlined"} 
            onClick={() => handleMatchSelect(item)}
            style={{ margin: '5px' }}
          >
            {item.name}
          </Button>
        ))
      )}
      </div>
      {selectedMatchInfo && <LiveScoringResults MatchInfo={selectedMatchInfo} />}
  </div>
      
  );
}

export default LiveScoringMenu;
