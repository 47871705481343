import React, { useState } from 'react';
import axios from 'axios';
import './Popup.css';
import { Dialog, DialogContent, DialogTitle, Button, TextField } from '@mui/material';

const Popup = ({ isOpen, onClose }) => {


  const [inputValue, setInputValue] = useState('');
  const [result, setResult] = useState('');

  const handleSubmit = () => {
    axios.post('https://onlyhf.com:5000/api/submit-url', { url: inputValue })
      .then(response => {
        // Handle successful response
        setResult(response.data.message);
      })
      .catch(error => {
        // Check if error response was received from the server
        if (error.response) {
          // Server responded with an error status code (4xx or 5xx)
          setResult(`${error.response.data.error}`);
        } else if (error.request) {
          // The request was made but no response was received
          console.error('Error submitting URL:', error.request);
          setResult('Failed to submit URL: No response received from server');
        } else {
          // Something happened in setting up the request that triggered an error
          console.error('Error submitting URL:', error.message);
          setResult('Failed to submit URL: Request setup error');
        }
      });
  };


  if (!isOpen) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add Match Results</DialogTitle>
      <DialogContent>
        <div>Only add IPSC/USPSA matches</div>
        <TextField
          className="input-field"
          label="PS Results URL"
          variant="outlined"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="outlined" onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSubmit}>Submit</Button>
        <p>{result}</p>
      </DialogContent>
    </Dialog>
  );
}

export default Popup;
