import React, { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import './StageCalc.css';

const StageCalc = ({}) => {

  const rowsNames = [
    createData('Alpha'),
    createData('Charlie'),
    createData('Delta'),
    createData('P/NS/M')
  ];

  const [time, setTime] = useState(0.00);
  const [minorMajor, setMinorMajor] = useState("Minor"); 
  const [fixedTime, setfixedTime] = useState(false); 
  const [cellValues, setCellValues] = useState(rowsNames.map(() => 0));
  const [totalRound, setRound] = useState(0);
  const [totalPoint, setPoint] = useState(0);
  const [hitFactor, setHitFactor] = useState(0);

  const handleCheckboxChange = (event) => {
    setfixedTime(event.target.checked); 
    const checked = event.target.checked;
    if (!checked)
    {
      setHitFactor(calculateHitFactor(totalPoint, time));
    }
    else
    {
      setHitFactor(totalPoint);
    }
  };

  const handleMinorMajorChange = (event) => {
    const newMinorMajor = event.target.value;
    setMinorMajor(newMinorMajor);
    const updatedPoints = calculatePoints(cellValues, newMinorMajor);
    setPoint(updatedPoints);
    setHitFactor(calculateHitFactor(updatedPoints, time));
  };

  const calculatePoints = (values, powerFactor) => {
    let totalPoints = 0;
    const points = powerFactor === 'Minor' ? rowsMinorPoints : rowsMajorPoints;
    values.forEach((value, index) => {
      const classPoints = points.find(item => item.Class === rowsNames[index].Class);
      if (classPoints) {
        totalPoints += value * classPoints.value;
      }
    });
    return totalPoints;
  };
  
  

  const handleChangePointChange = (index, newValue) => {
    if (!isNaN(newValue)) {
      const cappedValue = Math.min(1000, Math.max(0, newValue));
      setCellValues(prevValues => {
        const newValues = [...prevValues];
        newValues[index] = cappedValue;
        const lastroundIndex = newValues.length - 1;
        const lastIndex = newValues.length;

        const roundCount = newValues.slice(0, lastroundIndex).reduce((acc, currentValue) => acc + currentValue, 0);
        setRound(roundCount);

        const totalPoints = newValues.slice(0, lastIndex).reduce((acc, currentValue, index) => {
          let pointValue;
          switch (rowsNames[index].Class) {
            case "P/NS/M":
              pointValue = -10;
              break;
            default:
              pointValue = minorMajor === "Minor" ? rowsMinorPoints[index].value : rowsMajorPoints[index].value;
              break;
          }
          const calculatedPoints = currentValue * pointValue;

          const adjustedPoints = Math.max(0, acc + calculatedPoints);
          return adjustedPoints;
        }, 0);
        setPoint(totalPoints);
        setHitFactor(calculateHitFactor(totalPoints, time));

        return newValues;
      });
    }
  };
  

  const calculateHitFactor = (points, time) => {

    if (time > 0) {
      return (points / time).toFixed(4);
    } 
    else {
      return 0; 
    }
  };

  const handlePercentageInputBlur = () => {
    const value = parseFloat(time);
    if (isNaN(value)) {
      setTime("0");
    } else {
      const clampedValue = Math.min(500, Math.max(0, value));
      setTime(clampedValue.toFixed(2));
    }
  };

    const handleTimeChange = (e) => {
      const newTime = e.target.value;
      if (!isNaN(newTime) || e.target.value === "" || e.target.value === ".") {
        setTime(newTime);
        setHitFactor(calculateHitFactor(totalPoint, newTime));
      } else {
        setTime(0);
        setHitFactor(calculateHitFactor(totalPoint, 0));
      }
  };
  
    const rowsMinorPoints = [
      { Class: 'Alpha', value: 5 },
      { Class: 'Charlie', value: 3 },
      { Class: 'Delta', value: 1 },
      { Class: 'P/NS/M', value: -10 }
    ];


    const rowsMajorPoints = [
      { Class: 'Alpha', value: 5 },
      { Class: 'Charlie', value: 4 },
      { Class: 'Delta', value: 2 },
      { Class: 'P/NS/M', value: -10 }
    ];

  
    function createData(Class) {
      return { Class };
    }

    const handleIncrement = (index) => {
      handleChangePointChange(index, cellValues[index] + 1);
    };
  
    const handleDecrement = (index) => {
      handleChangePointChange(index, cellValues[index] > 0 ? cellValues[index] - 1 : 0);
    };

    const handleIncrementTime = () => {
      // Assuming time is in seconds
      const newTime = parseFloat(time) + 1; // Parse time as a float before incrementing
      setTime(newTime.toFixed(2));
      handleTimeChange({ target: { value: newTime.toFixed(2) } });
    };
    
    
    const handleDecrementTime = () => {
      const newTime = Math.max(0, time - 1); // Ensure time doesn't go below 0
      setTime(newTime.toFixed(2));
      handleTimeChange({ target: { value: newTime.toFixed(2) } });
    };
    
  
    return (
  
      <div className='HF'>
        <h2>Stage Calculator</h2>
        <p>Round Count: {totalRound}</p>
        <p>Power Factor:
        <label>
            <input
              type="radio"
              name="minorMajor"
              value="Minor"
              checked={minorMajor === "Minor"}
              onChange={handleMinorMajorChange}
            />
            Minor
        </label>
        <label>
          <input
            type="radio"
            name="minorMajor"
            value="Major"
            checked={minorMajor === "Major"}
            onChange={handleMinorMajorChange}
          />
          Major
        </label>
        </p>
        <p>Fixed Time
        <input
              type="checkbox"
              checked={fixedTime}
              onChange={handleCheckboxChange}
            />
            </p>

        <TableContainer className='MinHFtable'>
          <Table size="small" aria-label="a dense table" className='HF' style={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell align="center" >Scoring</TableCell>
                <TableCell align="center" >Points</TableCell>
                <TableCell align="center" >Amount</TableCell>
  
              </TableRow>
            </TableHead>
            <TableBody>
            <TableRow>
                <TableCell align="center" >Time</TableCell>
                <TableCell align="center" ></TableCell>
                <TableCell align="center" >
                  
                    <input type="text" 
                    className="input-field"
                    value={time} 
                    disabled={fixedTime} 
                    onChange={handleTimeChange}
                    onBlur={handlePercentageInputBlur}
                    style={{ width: '50px', textAlign: 'center' }}
                    />
                    <Button onClick={() => handleDecrementTime()} className="buttons">-</Button>
                    <Button onClick={() => handleIncrementTime()} className="buttons">+</Button>
                  
                </TableCell>
              </TableRow>
              {rowsNames.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center" >{row.Class}</TableCell>
                  <TableCell align="center">{minorMajor === "Minor" ? rowsMinorPoints[index].value : rowsMajorPoints[index].value}</TableCell>
                  <TableCell align="center">
                  
                    <input
                      type="text"
                      className="input-field"
                      value={cellValues[index]}
                      onChange={(e) => handleChangePointChange(index, parseInt(e.target.value))}
                      style={{ width: '50px', textAlign: 'center' }}
                    />
                    <Button onClick={() => handleDecrement(index)} className="buttons">-</Button>
                    <Button onClick={() => handleIncrement(index)} className="buttons">+</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className="Output">Hitfactor: {hitFactor}</Box>
        <Box className="Output">Total Points: {totalPoint}</Box>
      </div>
  );
};

export default StageCalc