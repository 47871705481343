import React, {useState} from 'react';
import './menu.css';

function Menu({ categories, onSelect }) {

  const [selectedCategory, setSelectedCategory] = useState(""); // State to store selected category

  const handleCategorySelect = (category) => {
    setSelectedCategory(category); // Update selected category
    onSelect(category); // Call onSelect function provided by parent component
  };

    return (
      <div className="menu">
      {categories.map((category, index) => (
        <div
          className={`menu-item ${selectedCategory === category ? "selected" : ""}`}
          key={index}
          onClick={() => handleCategorySelect(category)}
        >
          {category}
        </div>
        ))}
      </div>
    );
  }

  export default Menu;