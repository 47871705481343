import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Checkbox,
  TextField
} from '@mui/material';

const FilterNames = ({ namesList, onSave, selected_names }) => {
  const [selectedNames, setSelectedNames] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredNames, setFilteredNames] = useState([]);


  const [recalculateNameChecked, setRecalculateNameChecked] = useState(false);
  const [filterout, setFilterout] = useState(false);
  const PageSize = 10;
  const handleToggle = (shooterID, name) => {
    setSelectedNames(prevSelectedNames => {
      const isSelected = prevSelectedNames.some(item => item.id === shooterID);
      if (isSelected) {
        return prevSelectedNames.filter(item => item.id !== shooterID);
      } else {
        return [...prevSelectedNames, { id: shooterID, name: name }];
      }
    });
  };

  useEffect(() => {
    setSelectedNames(selected_names);
  }, [selected_names]);

  const handleApplyFilters = () => {
    onSave(selectedNames, recalculateNameChecked, filterout);
    setOpenFilter(false);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  useEffect(() => {
    const updatedFilteredNames = Object.entries(namesList)
      .filter(([_, name]) => !name.includes("-")) 
      .filter(([_, name]) => name.toLowerCase().includes(searchQuery.toLowerCase()));
    setFilteredNames(updatedFilteredNames);
    setCurrentPage(1);
  }, [namesList, searchQuery]);

  const totalPages = Math.ceil(filteredNames.length / PageSize);

  const startIndex = (currentPage - 1) * PageSize;
  const endIndex = Math.min(startIndex + PageSize, filteredNames.length);
  const visibleNames = filteredNames.slice(startIndex, endIndex);

  const handlePrevPage = () => setCurrentPage(currentPage - 1);
  const handleNextPage = () => setCurrentPage(currentPage + 1);
  return (
    <>
      <Button onClick={handleOpenFilter} style={{ position: 'relative' }}>
        Filter Names
        {selected_names.length > 0 && (
          <span style={{
            marginLeft: '5px', 
            backgroundColor: 'red',
            color: 'white',
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            {selected_names.length}
          </span>
        )}
      </Button>


      <Dialog
        open={openFilter}
        PaperProps={{ style: { minHeight: '450px', maxHeight: '450px' } }}
        onClose={handleCloseFilter}
      >
        <DialogTitle>Select Names to Filter</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">Names</FormLabel>


            <div>
              <TextField
                label="Search Name"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearch}
                style={{ marginBottom: '16px' }}
              />

              <FormGroup>
                {visibleNames.map(([shooterID, name]) => (
                  <FormControlLabel
                    key={shooterID}
                    control={
                      <Checkbox
                        checked={selectedNames.some(item => item.id === shooterID)}
                        onChange={() => handleToggle(shooterID, name)}
                        value={shooterID}
                      />
                    }
                    label={name}
                  />
                ))}
              </FormGroup>
              <div>
                <Button disabled={currentPage === 1} onClick={handlePrevPage}>Previous</Button>
                <span>{`Page ${currentPage} of ${totalPages}`}</span>
                <Button disabled={currentPage === totalPages} onClick={handleNextPage}>Next</Button>
              </div>
            </div>

          </FormControl>
        </DialogContent>
        <DialogActions>
          <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Checkbox
              checked={recalculateNameChecked}
              onChange={(e) => setRecalculateNameChecked(e.target.checked)}
              color="primary"
            />Recalc Names
          </label><br />
          <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Checkbox
              checked={filterout}
              onChange={(e) => setFilterout(e.target.checked)}
              color="primary"
            />Filterout
          </label>
          <Button onClick={handleCloseFilter} variant="outlined" color="secondary">
            Close
          </Button>
          <Button onClick={handleApplyFilters} variant="contained" color="primary">
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default FilterNames;
