import React, { useState, useEffect } from "react";
import "./Hundoscenario.css"
import MajorCalculation from './MajorCalculation';
import MinorCalculation from './MinorCalculation';
import { Stack, Pagination } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import "./Hitfactor.css";


const Hundoscenario = ({ selectedDivision, selectedClassifier }) => {
  const [data, setData] = useState([]);
  const [columnNames, setColumnNames] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page
  
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const minorDivisions = ["Production", "Carry Optics", "PCC", "Limited Optics"];
  const isMinorDivision = (division) => {
    return minorDivisions.includes(division);
  };



  const fetchData = async (division, classifier) => {
    try {
      const response = await fetch(`https://onlyhf.com:5000/api/classificationinfo?division=${division}&classifier=${classifier}`);
      const rawData = await response.json();
      let calculatedData = "";
      if (minorDivisions.includes(selectedDivision)) {
        calculatedData = MinorCalculation({ rawData, percentage: 100 });
      }
      else {
        calculatedData = MajorCalculation({ rawData, percentage: 100 });
      }
      if (calculatedData !== "") {
        setData(calculatedData);
      }
      else {
        console.error('Error calculating data!');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  

  useEffect(() => {
    if (selectedClassifier) {
      fetchData(selectedDivision, selectedClassifier);
    }
  }, [selectedDivision, selectedClassifier]);

  useEffect(() => {
    if (data.length > 0) {
      const innerArrayLength = data[0].length;
      if (innerArrayLength === 5) {
        setColumnNames(["Time", "Steel", "Alpha", "Charlie", "Delta"]);
      } else if (innerArrayLength === 3) {
        setColumnNames(["Alpha", "Charlie", "Delta"]);
      }
    }
  }, [data]);


  useEffect(() => {
    setCurrentPage(1);
  }, [selectedClassifier]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = data.slice(startIndex, endIndex);

  const rows = displayedData.map((row, index) => ({
    id: index,
    ...row.reduce((acc, value, colIndex) => {
      acc[columnNames[colIndex]] = value || "0";
      return acc;
    }, {}),
  }));

  const columns = columnNames.map((columnName, index) => ({
    field: columnName,
    headerName: columnName,
    align: 'center',
    sortable: false,
    flex: 1,
  }));

  const totalRows = rows.length;
const totalPages = Math.ceil(totalRows / 10);

  return (
    <div className='HundoHF'>
      <h2 variant="h2">
        Hundo Classifier Scenarios -{" "}
        {selectedDivision ? (
          <span>
            {isMinorDivision(selectedDivision) ? " (Minor)" : " (Major)"}
          </span>
        ) : (
          ""
        )}
      </h2>
      <div style={{ width: '350px', overflowX: 'auto', margin: '0 auto' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10} 
        page={currentPage - 1} 
        onPageChange={(newPage) => setCurrentPage(newPage + 1)} 
        disableColumnMenu
        disableColumnSelector
        disableColumnResize
        rowsPerPageOptions={[]}
        pagination
        hideFooter
      />
      <Stack
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            color="primary"
            shape="rounded"
          />
        </Stack>

      </div>
    </div>
  );
};

export default Hundoscenario