const MinorCalculation = ({ rawData, percentage }) => {
  // ... Perform calculations on modifiedData

  const calculatedResult = performCalculations(rawData, percentage);
  // Return the calculated result
  return calculatedResult;
};

const calculateHitFactor = (totalPoints, time) => {
  if (time === 0) {
    return totalPoints; // Avoid division by zero
  }
  return totalPoints / time;
};

const findTimeAboveHitFactor = (totalPoints, desiredHitFactor, currentTime) => {
  while (true) {
    const currentHitFactor = calculateHitFactor(totalPoints, currentTime.toFixed(2));
    if (currentHitFactor >= desiredHitFactor) {
      return currentTime;
    }

    currentTime -= 0.01;

    if (currentTime <= 0) {
      return currentTime;
    }
  }
};

const performCalculations = (rawData, percentage) => {
  const max_available_points = parseInt(rawData[0][1]);
  const max_hits = parseInt(rawData[0][3]);
  const fixed_steel_count = parseInt(rawData[0][2]);

  const fixed_time_value = rawData[0][4];

  const high_hitfactor = parseFloat(rawData[0][5]);
  const HFwanted = high_hitfactor * (percentage / 100);

  const alpha_points = 5;
  const steel_points = 5;
  const charlie_points = 3;
  const delta_points = 1;

  const time_per_shot = 0.175;
  const time_draw = 0.75;

  let best_cases = [];

  let remaining_points = max_available_points - (fixed_steel_count * steel_points);

  for (let alpha_count = 0; alpha_count <= Math.min(remaining_points / alpha_points, max_hits); alpha_count++) {
    let remaining_points_alpha = remaining_points - alpha_count * alpha_points;

    for (let charlie_count = 0; charlie_count <= Math.min(remaining_points_alpha / charlie_points, max_hits - alpha_count); charlie_count++) {
      let remaining_points_charlie = remaining_points_alpha - charlie_count * charlie_points;
      let max_delta_count = Math.min(remaining_points_charlie / delta_points, max_hits - alpha_count - charlie_count);

      for (let delta_count = 0; delta_count <= max_delta_count; delta_count++) {
        let total_points = (alpha_count * alpha_points) + (charlie_count * charlie_points) + (delta_count * delta_points) + (fixed_steel_count * steel_points);

        let time = total_points / HFwanted;

        if (time !== null) {
          let humantime = (max_hits * time_per_shot) + time_draw;
          let hitfactor = null;

          if (fixed_time_value) {
            if (total_points !== 0) {
              hitfactor = total_points;
              time = 0;
            }
          } else if (time !== 0) {
            hitfactor = total_points / time.toFixed(2);
          }


          if (hitfactor !== null && alpha_count + charlie_count + delta_count + fixed_steel_count === max_hits && humantime < time) {
            const firstTimeAboveHitFactor = findTimeAboveHitFactor(total_points, HFwanted, time);
            best_cases.push([firstTimeAboveHitFactor.toFixed(2).padStart(5, "0"), fixed_steel_count, alpha_count, charlie_count, delta_count]);
          } else if (hitfactor !== null && hitfactor === Math.ceil(HFwanted) && alpha_count + charlie_count + delta_count === max_hits && fixed_time_value) {
            best_cases.push([alpha_count, charlie_count, delta_count]);
          }
        }
      }
    }
  }
  best_cases = best_cases.sort((a, b) => b[0] - a[0])
  return best_cases;
};
export default MinorCalculation;
